// TODO
// button styles (from button branch)
// eyebrow styles (from atomic text styles)

@mixin inactiveSlideState() {
  // transform: translateX(-125%); Example of a slide animation
  opacity: 0;
}

@mixin activeSlideState() {
  // transform: translateX(0); Example of a slide animation
  opacity: 1;
}

@keyframes slideContent {
  0% {
    @include inactiveSlideState;
  }

  12% {
    @include activeSlideState;
  }

  94% {
    @include activeSlideState;
  }

  100% {
    @include inactiveSlideState;
  }
}

.b-testimonialCarousel {
  $borderValue: 2px solid rgba(255, 255, 255, 0.5);
  --eyebrow-color: var(--color-grayscale-100);
  $margin-bottom: 3.5rem; // Used for the headshot and
  border-radius: $global-radius-base;
  background-color: var(--color-core-brand);
  color: var(--color-grayscale-100);
  overflow: hidden;

  .c-withLayer {
    height: 100%;
  }

  &__controls {
    .c-wrapper {
      height: 100%;
      position: relative;
    }
  }

  .swiper {
    height: 100%;

    &-pagination {
      --swiper-pagination-bullet-size: 1rem;

      bottom: 3rem !important;
      left: 50% !important;
      transform: translateX(-50%) !important;

      .swiper-pagination-bullet {
        background-color: get-color('core-secondary-100');
        border: transparent 4px solid;
        opacity: 1;

        &-active {
          background-color: transparent;
          border-color: var(--theme-primary);
        }
      }
    }

    /* #region - Animations */
    .b-testimonialSlide {
      background-color: #000;
      &__bgImg {
        transform: scale(1);
        transition: transform 9s ease-in-out;
      }

      &__content {
        @include inactiveSlideState;
      }

      &__headshot img {
        transform: scale(1.5);
        transition: transform 9s ease-in-out;
      }
    }

    .swiper-slide {
      overflow: hidden;
      &-active {
        &:only-child {
          .b-testimonialSlide__content {
            @include activeSlideState;
            transition: opacity 1.2s ease-in-out;
          }
        }

        &:not(li:only-child) {
          .b-testimonialSlide__content {
            animation-name: slideContent;
            animation-duration: 10s;
          }
        }

        .b-testimonialSlide {
          &__bgImg {
            transform: scale(1.4) translateY(14%);
            transition: transform 8s ease-in-out;
          }

          &__headshot img {
            transform: scale(1);
            transition: transform 1s ease-in-out;
          }

          q {
            line-height: 44px;
            letter-spacing: -1px;
          }
        }
      }
    }
    /* #endregion - Animations */
  }

  .b-testimonialSlide {
    min-height: clamp(33rem, 90vw, 43.75rem);
    padding-inline: 1rem;

    &__bgImg {
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &__contentFlow {
      flex-basis: 100%;
    }

    &__content {
      align-items: center;
    }

    &__headshot {
      $headshot-size: 7.5rem;
      width: $headshot-size;
      height: $headshot-size;
      position: absolute;
      right: 0;
      bottom: 1.5rem;
      border: 0.5rem solid var(--color-grayscale-100);
      border-radius: 50%;
    }
  }
}
