.b-listingTable {
  a {
    text-decoration: underline;
  }

  thead {
    --box-padding: #{get-space('400')} #{get-space('100')};
    background-color: get-color('neutrals-primary-300');
  }

  tbody {
    --box-padding: #{get-space('400')} #{get-space('100')};
    // Alternating colors like this may work well as a mixin
    tr {
      &:nth-child(even) {
        background-color: get-color('neutrals-tertiary-400');
      }
    }
  }

  .b-button {
    --box-padding: 0;
    padding: 0;
  }

  &--properties {
    width: 100%;

    thead {
      background-color: get-color('neutrals-tertiary-500');
      font-size: get-text-size('00');
      font-weight: 700;
      letter-spacing: 0.25em;
      text-transform: uppercase;

      tr th {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(5),
        &:nth-child(8),
        &:nth-child(9) {
          display: none;
        }

        > span {
          display: none;

          @include media-query('lg') {
            display: inline;
          }
        }
      }
    }

    tbody tr {
      &.odd td{
        background-color: get-color('neutrals-tertiary-100');
      }

      &.even td{
        background-color: get-color('neutrals-tertiary-400');
      }

      td{
        font-size: get-text-size('base');
        text-align: center;
        vertical-align: middle;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(5),
        &:nth-child(8),
        &:nth-child(9) {
          display: none;
        }

        &:nth-child(1),
        &:nth-child(8) {
          font-weight: 700;
        }

        &:nth-child(1) {
          letter-spacing: 0.01em;
        }

        &:nth-child(8) {
          font-size: get-text-size('00');
          letter-spacing: 0.25em;
          text-transform: uppercase;
        }
      }

      &.details {
        td {
          background: #fff;
        }

        td:nth-child(1) {
          display: table-cell;
          padding: #{get-space('400')};
        }
      }
    }

    .b-propertyStatus {
      color: get-color('neutrals-primary-500');
      justify-content: center;
    }

    button {
      padding-inline: 10px;
    }

    .b-listingTable__details {
      font-weight: 400;
      letter-spacing: 0.01em;

      > div {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      .b-propertyStatus {
        font-size: get-text-size('0');
        letter-spacing: 0.167em;
      }

      .b-propertyId {
        text-decoration-line: underline;
      }
    }

    @include media-query('lg') {
      thead {
        tr th {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(5),
          &:nth-child(8),
          &:nth-child(9) {
            display: table-cell;
          }

          &:nth-child(4),
          &:nth-child(10) {
            display: none;
          }
        }
      }

      tbody {
        tr td {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(5),
          &:nth-child(8),
          &:nth-child(9) {
            display: table-cell;
          }

          &:nth-child(4),
          &:nth-child(10) {
            display: none;
          }
        }

        tr.details {
          display: none;
        }
      }
    }
  }
}

.b-modal {
  $self: &;
  &.b-listingTableModal {
    #{$self}__body {
      --wrapper-max-width: 62rem;
      --wrapper-width: var(--wrapper-width, clamp(16rem, 95vw, 75rem));
    }
  }
}