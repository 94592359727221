// This could probably be better named color overlay and maybe use rgba instead?
// Which could allow for easier customization of opacity
$triggerClass: 'u-hoverTarget-darken';
$elementEffectedClass: 'u-hoverEffect-darken';

@mixin darkenActiveState() {
  &::after {
    opacity: .2;
  }
}

@mixin darkenElementEffected() {
  .#{$elementEffectedClass} {
    @include darkenActiveState;
  }
}

// Used within both trigger class and repsonsive variations of the 
// trigger class
@mixin darkenHoverActiveException() {
  &--active {
    @include darkenElementEffected;
  }
}

/* Effect Class
 * Main styling for the desired effect - a black color overlay layer
 * leveraging the ::after psuedo element
 */
.#{$elementEffectedClass} {
  &::after {
    position: absolute;
    content: '';
    background-color: get-color('grayscale-primary-500');
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity .3s ease-in-out;
  }
  
  &:focus,
  &:hover {
    @include darkenActiveState;
  }
}

/* Trigger Class
 * A parent class for effect styling to target and trigger through exceptions. 
 * Based around responsive design and hover
 */
.#{$triggerClass} {
  @include darkenHoverActiveException;
  
  &:focus,
  &:hover {
    @include darkenElementEffected;
  }
}

.max-md\:#{$triggerClass} {
  @media only screen and (max-width: 879.99px) {
    @include darkenHoverActiveException;
  }
}