/*
 * Listing Base File
 * Primarly contains styles for _listingBase files that span across
 * Properties, Agents, & News
 * This file also contains exceptions and classes specific to the 3
 * categories above.
 */

/*
 * Main Content Columns Wrapper
 */
.b-listings {
  $listingsBreakpoint: '1005px';
  --auto-grid-min-item-size: auto;
  @include c-auto-grid-ordering-breakpoint($listingsBreakpoint);

  li.ais-Hits-item {
    padding-block: get-space('500');
  }

  @media only screen and (min-width: $listingsBreakpoint) {
    --auto-grid-min-item-size: clamp(29rem, 50vw, 28rem);
    .listingMapColumn {
      padding-inline-start: get-space('600');
    }
  }
}

.b-listingInputs {
  
  input {
    background-color: get-color('neutrals-tertiary-400');
    border-radius: $global-radius-base;

    &[type=checkbox] {
      accent-color: get-color('neutrals-tertiary-100');

      &:checked {
        background-color: #000;
      }
    }
  }

  .b-searchDropdown {
    flex-grow: 1;
    
    .b-listingDropdown__content, .b-listingDropdown__caret {
      display: none !important;
    }
  }

  #state {
    max-height: 40vh;
    overflow-y: auto;
  }

  &--properties {
    .b-listingDropdown {
      &:not(:first-child) {
        --split-gutter: calc(#{get-space('400')} / 2);
        --facet-dropdown-width-percent: 50%;
        flex-basis: calc(var(--facet-dropdown-width-percent) - var(--split-gutter));

        @include media-query('md') {
          // Split gutter is equal to value of space divided by
          // number of items minus one
          --split-gutter: calc(#{get-space('400')} * .75);
          --facet-dropdown-width-percent: 25%;
        }

        @include media-query('lg') {
          flex-basis: auto;
        }
      }
    }
  }

  &--agents {
    .b-listingDropdown, .b-listingSortBy {
      &:not(.b-searchDropdown) {
        --split-gutter: calc(#{get-space('400') / 2});
        --facet-dropdown-width-percent: 50%;
        flex-basis: calc(var(--facet-dropdown-width-percent) - var(--split-gutter));

        @include media-query('md') {
          flex-basis: auto;
        }
      }
    }
  }

  &--news {
    flex-basis: 100%;

    .b-listingDropdown, .b-listingSortBy {
      --split-gutter: calc(#{get-space('400') / 2});
      --facet-dropdown-width-percent: 50%;
      flex-basis: calc(var(--facet-dropdown-width-percent) - var(--split-gutter));
    }

    @include media-query('sm') {
      flex-basis: auto;

      .b-listingDropdown, .b-listingSortBy {
        flex-basis: auto;
      }
    }
  }
}

.ais-RefinementList-item {
  cursor: pointer;
}

.ais-RangeInput {
  input {
    min-width: 7rem;
  }

  button {
    background-color: get-color('core-primary-100');
    border-radius: $global-radius-base;
    color: get-color('grayscale-primary-100');
  }
}

.b-agentListing {
  &:hover {
    .b-listingTitle {
      color: get-color('core-primary-100');
    }
  }
}

.b-moreDropdown {
  --auto-grid-min-item-size: 7.25rem;
}

#map {
  max-height: 60vh;

  @include media-query('lg') {
    max-height: 700px;
  }
}

.b-newsListings {
  --auto-grid-min-item-size: 18rem;

  .ais-Stats-text {
    font-weight: 600;
  }
}

// Hidden input used for save search
#propertyKeywords {
  display: none;
}

@media only screen and (max-width: '1004.95px') {
  #listingsFull {
    display: none;
  }

  #listings {
    display: block!important;
  }
}
