.b-richText {
  --wrapper-width-max: 70ch;
  --wrapper-width: 70ch;

  figure {
    margin-inline: auto;
  }

  ul, ol {
    margin-left: 1rem;

    li {
      &::marker {
        color: get-color('core-primary-500');
      }
    }
  }

  h1, h2, h3 {
    font-weight: 700;
  }

  h4, h5, h6 {
    font-weight: 600;
  }

  h4 {
    font-size: calc(26em / 18);
  }

  h5 {
    font-size: calc(24em / 18);
  }

  h6 {
    font-size: calc(20em / 18);
  }

  a:not(.b-anchorComplex) {
    color: var(--theme-primary);
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .b-pseudoHr {
    max-width: 1.875rem;
  }
}
