/* Property Listing Callout
 */

.b-propertyListingCallout {
  $self: &;

  margin-bottom: get-space('800');
  position: relative;

  &__link {
    --box-padding: #{get-space('800')};

    flex-direction: column;
    overflow: hidden;

    .headline {
      line-height: 1.1;
      max-width: 17ch;
    }

    #{$self} {
      &__contentWrapper {
        // Content Props
        --box-padding: #{get-space('400')};
        --icon-size: 2rem;
        // Reveal Props - reveal props need to exist here to correctly pass component specific variables
        --content-size: var(--icon-size);
        --item-reveal-padding: var(--box-padding);
        --item-reveal-margin: #{get-space('500')};

        .b-icon {
          color: get-color('grayscale-tertiary-400');
        }
      }
    }
  }

  &__cards {
    flex-basis: 100%;

    .properties {
      margin-bottom: get-space('700');
      text-transform: uppercase;

      &__eyebrow {
        flex-grow: 1;

        p {
          @include bg-color(#f6f5e6, 1);
          font-size: get-text-size('0');
          line-height: 18px;
          letter-spacing: 2px;
          padding: 10px 20px;
          text-transform: uppercase;
        }
      }

      &__action {
        color: get-color('core-primary-500');
        font-size: get-text-size('00');
        letter-spacing: 1px;
        flex-grow: 1;
        align-items: flex-end;
      }
    }
  }

  @include media-query('md') {
    &__cards {
      flex-basis: auto;
    }
  }
}

.b-propertyListingCallOutCard {
  --gutter: #{get-space('600')};

  .card-property {
    &__image {
      img {
        height: 8.125rem;
        object-fit: cover;
        width: 8.125rem;
        max-width: none;
      }
    }

    &__desc {
      margin-left: get-space('400');
      transform: translateX(0);
      transition: transform .3s ease-in-out;

      .title {
        color: get-color('neutrals-primary-500');
        line-height: 32px;
        font-style: normal;
      }

      .location,
      .price,
      .size-acreage {
        color: get-color('neutrals-primary-500');
        font-size: get-text-size('1');
        line-height: 28px;
      }
      .location {
        display: -webkit-box;
        -webkit-line-clamp: var(--line-clamp, 3);
        -webkit-box-orient: vertical;
        overflow: hidden;
        hyphens: auto;
      }

      @include media-query('lg') {
        margin-left: get-space('800');
      }
    }
  }

  .b-propertyFader__faderControls {
    font-size: .75em;
  }

  &:hover {
    @include media-query('md') {
      .card-property {
        &__desc {
          transform: translateX(1rem);
        }
      }
    }
  }

  @media only screen and (max-width: 879.99px) {
    @include propertyFaderControlsActive;
  }
}
