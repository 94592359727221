.b-propertyDetailHero {
  --auto-grid-min-item-size: 18rem;

  header {
    --repel-gutter: #{get-space('200')};

    & > div,
    & > header {
      flex-basis: 100%;
    }

    @include media-query('md') {
      & > div,
      & > header {
        flex-basis: auto;
      }

      .b-propertyDetailHeader {
        order: 1;

        &__propertyStatus {
          letter-spacing: 0.1875rem;
        }

        & dl {
          font-size: 1rem;
        }
      }

      nav {
        order: 2;
      }
    }
  }

  &__nav {
    gap: get-space('600');
    // gap: get-space("200"); TODO: Look into why I updated gap here
    margin: get-space('600') 0;
    justify-content: center;

    @include media-query('md') {
      justify-content: flex-start;
      margin: unset;
    }
  }

  &__navIcon {
    // TODO: Look into these
    // --nav-color: var(--theme-primary);
    // --nav-background: transparent;

    span {
      color: var(--nav-color);
    }

    &:not([data-active]) {
      --nav-color: #{get-color('neutrals-primary-500')};
      color: var(--nav-color);
    }

    &[data-active],
    &:hover {
      --nav-color: #{get-color('grayscale-tertiary-400')};
      --nav-background: var(--theme-primary);
      background: var(--nav-background);
      color: var(--nav-color);
      &:hover,
      &:focus {
        color: var(--nav-color);
      }
    }
  }

  &__carousel {
    img {
      object-fit: cover;
      object-position: center center;
      max-height: 700px;
      width: 100%;
    }

    .swiper {
      &-slide {
        height: unset !important;
      }

      // &-controls {
      //   display: none;

      //   @include media-query('md') {
      //     display: flex;
      //   }
      // }
    }
  }

  #propertyDetail__imageGridButton {
    display: none;
    --box-padding: #{get-space('200')};
    border-radius: $global-radius-base;
    bottom: get-space('600'); // Same as .swiper-controls
    line-height: 1;
    position: absolute;
    right: get-space('600');
    z-index: 100;

    @include media-query('md') {
      display: block;
    }
  }
}
