.b-propertyListing {
  color: get-color('neutrals-primary-500');

  .b-propertyFader {
    height: 120px;
    width: 120px;

    @include media-query('md') {
      height: 200px;
      max-width: 300px;
      min-width: 1px;
      width: 300px;
    }
  }

  &:focus-visible {
    .b-imageFader {
      @include imageFader();
    }
  }

  .b-saleTypeText {
    color: var(--theme-primary);
  }

  &__content {
    flex-basis: calc(100% - 15rem);
    flex-grow: 1;
    font-size: 0.8888888rem;
    gap: 0;
  }

  &__address {
    font-style: italic;
    font-size: get-text-size('1');
    letter-spacing: 0.0625em;
  }

  &__types,
  &__agents {
    color: get-color('neutrals-primary-500');

    h5 {
      font-size: get-text-size('00');
      font-weight: 800;
      letter-spacing: 0.167em;
    }

    p {
      font-size: get-text-size('base');
      letter-spacing: 0.01em;
      margin-top: #{get-space('100')};
    }
  }

  &__info {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: center;

    &__type {
      font-size: get-text-size('00');
      font-weight: 800;
      letter-spacing: 0.063em;
    }

    &__content {
      display: flex;
      justify-content: space-between;
    }

    .u-text-00 .b-listingTitle {
      font-size: get-text-size('00');
    }

    &--desktop {
      display: none;
      --gutter: #{get-space('500')};
      --repel-gutter: #{get-space('200')};
    }

    @include media-query('lg') {
      display: none;

      &__type {
        font-size: get-text-size('1');
      }

      &--desktop {
        display: flex;
        justify-content: flex-start;
      }
    }
  }

  &__action,
  &__additionalInfo {
    display: none;

    @include media-query('lg') {
      display: flex;
    }
  }

  &__priceOrDate {
    & * {
      @include media-query('lg') {
        font-size: get-size('1');
      }
    }

    .b-icon {
      color: var(--theme-primary);
    }
  }

  &:hover {
    color: get-color('neutrals-primary-500');
  }

  &--full {
    --cluster-vertical-alignment: stretch;
    --flow-space: #{get-space('600')};

    @include media-query('sm') {
      & > div {
        flex-basis: 20%;
      }
    }

    & > div:last-of-type {
      justify-content: space-between;
    }
  }

  @include media-query('md') {
    --gutter: #{get-space('600')};
    justify-content: space-between;
  }
}

#propertyStatus {
  .ais-RefinementList {
    &-item {
      border-radius: $global-radius-base;
      padding-inline: #{get-space('200')};
      &:focus,
      &:hover {
        background-color: get-color('neutrals-tertiary-300');
      }
    }

    &-label {
      position: relative;
    }

    &-checkbox {
      position: absolute;
      opacity: 0;
      &:focus-visible + .ais-RefinementList-labelText {
        outline: 0.125rem solid -webkit-focus-ring-color;
      }
    }

    input {
      &[value='Active'] + span {
        &:before {
          background-color: $activeColor;
        }
      }

      &[value='Sold'] + span {
        &:before {
          background-color: $soldColor;
        }
      }

      &[value='Under Contract'],
      &[value='Inactive'],
      &[value='Contract Pending'],
      &[value='Pending Approval'] {
        & + span {
          &:before {
            background-color: $pendingColor;
          }
        }
      }
    }
  }
}

#listingTypes {
  max-width: 350px;
  padding: 0;

  .ais-RefinementList {
    li {
      a,
      button {
        &:hover,
        &:focus {
          background-color: #{get-color('neutrals-tertiary-500')};
        }
      }

      a {
        align-items: center;
        display: flex;
        height: 64px;
        padding-left: 1rem;
        padding-right: 1rem;

        &.parent-enabled {
          pointer-events: none;

          & input {
            opacity: 0.5;
          }
        }
      }

      &.hasSublist {
        position: relative;

        &.active {
          ul {
            display: block;
          }

          & .sublistTrigger {
            .b-icon {
              color: #{get-color('core-primary-500')};
              transform: rotate(0deg);
            }
          }
        }

        .sublistTrigger {
          @include absolute($top: 0, $right: 0);
          align-items: center;
          display: flex;
          height: 4rem;
          justify-content: center;
          margin-left: auto;
          width: 4rem;

          .b-icon {
            height: 8px;
            transform: rotate(-180deg);
            transition: transform ease-in-out 0.3s;
            width: 14px;
          }
        }
      }

      ul {
        display: none;

        li {
          a {
            padding-left: 2rem;
          }
        }
      }
    }

    &-checkbox {
      background-color: get-color('grayscale-tertiary-400');
      border-radius: 5px;
      cursor: pointer;
      height: 24px;
      margin-right: 0.5rem;
      width: 24px;

      &:checked {
        accent-color: get-color('core-primary-500');
      }
    }
  }
}

#listingPrice {
  & .b-listingDropdown__controls .c-cluster {
    &::after {
      @include pseudo;
      content: 'Auctions won’t display if a price range is selected.';
      font-size: 11px;
      font-style: italic;
      height: auto;
      text-align: center;
      width: 100%;
    }
  }
}

#propertyTypes,
#landTypes {
  .ais-RefinementList {
    --auto-grid-min-item-size: 7rem;
    line-height: 1.1;
    text-align: center;

    &-item {
      border-radius: $global-radius-base;

      &--selected {
        background-color: get-color('core-primary-500');
        color: get-color('grayscale-tertiary-400');

        & .ais-RefinementList-labelText {
          font-weight: 500;
        }
      }

      &:focus,
      &:hover {
        background-color: get-color('core-primary-500');
        color: get-color('grayscale-tertiary-400');
      }
    }

    &-label {
      display: flex;
      align-items: center;

      input {
        background-color: get-color('grayscale-tertiary-400');
        width: 24px;
        height: 24px;
        margin-right: 0.5rem;
        border-radius: 5px;
      }
    }
  }
}

#auctionType {
  white-space: nowrap;

  input {
    display: none;
  }
}

.ais-RefinementList-item {
  cursor: pointer;

  * {
    cursor: pointer;
  }
}
