.b-stockListing {
  &__canvas {
    width: 100px !important;
    height: 50px !important;
  }

  &__table {
    border-collapse: collapse;
    width: 100%;
    text-align: center;

    td,
    th {
      padding: 10px;
      vertical-align: middle; /* Align content vertically in cells */
    }

    img {
      width: 80px;
    }

    thead {
      border-bottom: 2px solid #f6f5e6;
      letter-spacing: 0.125em;
      padding-block: 0.625em;
      padding-inline: 1.25em;
      width: fit-content;
    }
  }

  &__title {
    max-width: 165px;
  }
}
