.b-basicCard {
  $self: &;
  --n: 4;
  --d: 3;
  --fader-delay: 2s;
  --box-light: #{get-color('neutrals-primary-500')};
  --icon-spacing: 0.5em;

  a#{$self} {
    &__link {
      .c-flow {
        --box-light: #{get-color('neutrals-primary-500')};
      }

      #{$self} {
        &__top-content {
          transition: color 0.3s ease-in-out;
        }
      }

      &:focus-visible,
      &:focus,
      &:hover {
        #{$self} {
          &__top-content {
            color: var(--theme-primary);
          }
        }
      }
    }
  }

  .b-propertyFader {
    --n: 4;
    --d: 3;
  }

  &__copyBlock {
    width: 100%;
    #{$self}__copyBlock__type {
      color: var(--theme-primary);
      font-size: get-text-size('0');
      letter-spacing: 2px;
      line-height: 18px;
      text-transform: uppercase;
    }

    &__header {
      font-size: get-text-size('2');
      letter-spacing: -1px;
      line-height: 32px;
    }
  }

  &__auction-header {
    font-size: get-text-size('0');
  }

  &__property-header {
    width: 100%;

    &__location {
      flex-grow: 1;
      font-size: get-text-size('0');
      letter-spacing: 0.01em;
      line-height: 28px;

      &--truncate {
        display: -webkit-box;
        -webkit-line-clamp: var(--line-clamp, 3);
        -webkit-box-orient: vertical;
        overflow: hidden;
        hyphens: auto;
      }
    }

    &__status {
      align-items: center;
      &__text {
        font-size: get-text-size('0');
        letter-spacing: 2px;
        line-height: 18px;
        text-transform: uppercase;
      }
      &--active {
        align-self: center;
        background-color: get-color('core-primary-500');
        border-radius: 50%;
        display: inline-block;
        height: 12px;
        margin-right: get-space('200');
        width: 12px;
      }
    }
  }

  &__property-info {
    width: 100%;
    font-size: get-text-size('0');
    letter-spacing: 0.01em;

    &__tract {
      flex-grow: 1;

      &_tract {
        margin-right: get-space('200');
      }
    }
  }

  .b-propertyStatus {
    color: get-color('neutrals-primary-100');
  }

  &:hover {
    @include propertyFaderHover;
  }

  @include media-query('sm') {
    flex: 0 1 100%;
  }

  @include media-query('lg') {
    flex: 0 1 33.3%;
  }
}
