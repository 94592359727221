/*
Overlay
*/
@mixin overlay($pseudo: true, $top: auto, $right: auto, $bottom: auto, $left: auto) {
    @include absolute($top, $right, $bottom, $left);
    @if $pseudo {
        @include pseudo();
    }
    height: 100%;
    width: 100%;
}