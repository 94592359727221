/* Hover Bar
 * A simple div using position absolute to sit overtop other content
 * Paired w/ simple reveal can be shown on hover only + mobile sizes
 */

.b-hoverBar {
  --hoverbar-size: 1.25rem;
  
  position: absolute;

  &--top, &--bottom {
    height: var(--hoverbar-size);
    left: 0;
    width: 100%;
  }

  &--right, &--left {
    width: var(--hoverbar-size);
    top: 0;
    height: 100%;
  } 

  &--top {
    bottom: calc(100% - var(--hoverbar-size));
  }

  &--right {
    right: calc(100% - var(--hoverbar-size));
  }

  &--bottom {
    top: calc(100% - var(--hoverbar-size));
  }

  &--left {
    left: calc(100% - var(--hoverbar-size));
  }
}