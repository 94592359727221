/* Styling for layout component sideBarRight */
.l-sideBar {
  --sidebar-width: 21.875rem;
  --sidebar-gutter: 3rem;

  .c-withSidebar--rtl {
    // Content
    & > :first-child {
      order: 2;
    }

    & > :last-child {
      order: 1;
    }
  }

  @media only screen and (min-width: 838px) {
    --sidebar-gutter: 7.5rem;
    .c-withSidebar--rtl {
      // Content
      & > :first-child {
        order: 1;
      }
  
      & > :last-child {
        order: 2;
      }
    }
  }
}