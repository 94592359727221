.b-profilePagination {
  &__number,
  &__button {
    width: 40px;
    height: 40px;

    a {
      color: get-color('neutrals-primary-100');
    }

    &--enabled {
      background-color: get-color('core-primary-500');
      color: get-color('neutrals-tertiary-100');
    }

    &--disabled {
      background-color: get-color('neutrals-tertiary-500');
      color: get-color('neutrals-tertiary-100');
    }
  }
}
