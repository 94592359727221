/**
 * IMPORTED TOKENS
 * These are generated with the design tokens data
*/
@import 'tokens';

/**
 * IMPORTED THEMES
 * These themes are for dark/light mode and abstract CSS Custom
 * property references to design tokens into more contextual references
*/
@import 'themes';

/// BASE SIZE
/// All calculations are based on this. It’s recommended that
/// you keep it at 1rem because that is the root font size. You
/// can set it to whatever you like and whatever unit you like.
///
$gorko-base-size: 1rem;

/// SIZE SCALE
/// This is a Major Third scale that powers all the utilities that
/// it is relevant for (font-size, margin, padding). All items are
/// calcuated off the base size, so change that and cascade across
/// your whole project.
///
// $gorko-size-scale: (
//   '300': $gorko-base-size * 0.8,
//   '400': $gorko-base-size,
//   '500': $gorko-base-size * 1.25,
//   '600': $gorko-base-size * 1.6,
//   '700': $gorko-base-size * 2,
//   '900': $gorko-base-size * 3
// );

/// COLORS
/// Colors are shared between backgrounds and text by default.
/// You can also use them to power borders, fills or shadows, for example.
///
// $gorko-colors: (
//   'dark': '#1a1a1a',
//   'light': '#f3f3f3'
// );

/// CORE CONFIG
/// This powers everything from utility class generation to breakpoints
/// to enabling/disabling pre-built components/utilities.
///
$gorko-config: (
  'namespace': (
    'prefix': 'u-',           // string
    'classes': true,          // boolean or string
    'css-vars': false         // boolean or string
  ),
  'css-vars': (
    'color': $gorko-colors,
    'themes': $gorko-themes,
  ),
  'bg': (
    'items': $gorko-theme-keys,
    'output': 'standard',
    'property': 'background',
  ),
  't-bg': (
    'items': $gorko-colors,
    'output': 'standard',
    'property': 'background',
  ),
  'box': (
    'items': (
      'hidden': 'none',
      'block': 'block',
      'inline-block': 'inline-block',
      'flex': 'flex',
      'inline-flex': 'inline-flex'
    ),
    'output': 'responsive',
    'property': 'display',
  ),
  'color': (
    'items': $gorko-theme-keys,
    'output': 'standard',
    'property': 'color',
  ),
  't-color': (
    'items': $gorko-colors,
    'output': 'standard',
    'property': 'color',
  ),
  'flex-dir': (
    'items': (
      'row': 'row',
      'rev': 'row-reverse',
      'column': 'column',
    ),
    'output': 'responsive',
    'property': 'flex-direction',
  ),
  'flex': (
    'items': (
      'none': 'none',
      'auto': 'auto',
      '1': '1',
      '2': '2',
      '3': '3',
    ),
    'output': 'responsive',
    'property': 'flex',
  ),
  'flex-basis': (
    'items': (
      'auto': 'auto',
      '25': '25%',
      '33': '33%',
      '50': '50%',
      '66': '66%',
      '100': '100%',
    ),
    'output': 'responsive',
    'property': 'flex-basis'
  ),
  'flex-align': (
    'items': (
      'baseline': 'baseline',
      'start': 'flex-start',
      'center': 'center',
      'end': 'flex-end',
    ),
    'output': 'responsive',
    'property': 'align-items',
  ),
  'flex-self': (
    'items': (
      'center': 'center',
      'end': 'end',
    ),
    'output': 'responsive',
    'property': 'align-self',
  ),
  'flex-justify': (
    'items': (
      'baseline': 'baseline',
      'start': 'flex-start',
      'center': 'center',
      'end': 'flex-end',
      'between': 'space-between',
      'around': 'space-around'
    ),
    'output': 'responsive',
    'property': 'justify-content',
  ),
  'flex-order': (
    'items': (
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
      '8': '8',
      '9': '9',
      '10': '10',
    ),
    'output': 'responsive',
    'property': 'order',
  ),
  'flex-wrap': (
    'items': (
      'yes': 'wrap',
      'no': 'nowrap',
    ),
    'output': 'responsive',
    'property': 'flex-wrap',
  ),
  'flow-space': (
    'items': $gorko-space-scale,
    'output': 'standard',
    'property': '--flow-space',
  ),
  'font': (
    'items': $gorko-fonts,
    'output': 'standard',
    'property': 'font-family',
  ),
  'gap-inline': (
    'items':
      map-merge(
        $gorko-space-scale,
        (
          'auto': 'auto',
        )
      ),
    'output': 'responsive',
    'property': 'margin-inline',
  ),
  'gap-top': (
    'items': $gorko-space-scale,
    'output': 'responsive',
    'property': 'margin-top',
  ),
  'gap-bottom': (
    'items': $gorko-space-scale,
    'output': 'responsive',
    'property': 'margin-bottom',
  ),
  'gap-right': (
    'items': $gorko-space-scale,
    'output': 'responsive',
    'property': 'margin-right',
  ),
  'gap-left': (
    'items': $gorko-space-scale,
    'output': 'responsive',
    'property': 'margin-left',
  ),
  'gutter': (
    'items': $gorko-space-scale,
    'output': 'responsive',
    'property': '--gutter',
  ),
  'hidden': (
    'items': (
      'yes': 'none',
      'no': 'revert',
    ),
    'output': 'responsive',
    'property': 'display',
  ),
  'leading': (
    'items': (
      'flat': '1.2',
      'short': '1.35',
      'regular': '1.7',
    ),
    'output': 'standard',
    'property': 'line-height',
  ),
  'measure': (
    'items': (
      'short': '40ch',
      'long': '65ch',
    ),
    'output': 'standard',
    'property': 'max-width',
  ),
  'pad': (
    'items': $gorko-space-scale,
    'output': 'responsive',
    'property': 'padding',
  ),
  'pad-bottom': (
    'items': $gorko-space-scale,
    'output': 'responsive',
    'property': 'padding-bottom',
  ),
  'pad-left': (
    'items': $gorko-space-scale,
    'output': 'responsive',
    'property': 'padding-left',
  ),
  'pad-right': (
    'items': $gorko-space-scale,
    'output': 'responsive',
    'property': 'padding-right',
  ),
  'pad-top': (
    'items': $gorko-space-scale,
    'output': 'responsive',
    'property': 'padding-top',
  ),
  'pad-block': (
    'items': $gorko-space-scale,
    'output': 'responsive',
    'property': 'padding-block',
  ),
  'pad-inline': (
    'items': $gorko-space-scale,
    'output': 'responsive',
    'property': 'padding-inline',
  ),
  'position': (
    'items': (
      'relative': 'relative !important',
      'absolute': 'absolute',
      'fixed': 'fixed'
    ),
    'output': 'standard',
    'property': 'position',
  ),
  'ta': (
    'items': (
      'center': 'center',
      'left': 'left',
      'right': 'right',
    ),
    'output': 'standard',
    'property': 'text-align',
  ),
  'text': (
    'items': $gorko-size-scale,
    'output': 'responsive',
    'property': 'font-size',
  ),
  'tt': (
    'items': (
      'upper': 'uppercase',
    ),
    'output': 'standard',
    'property': 'text-transform',
  ),
  'weight': (
    'items': $gorko-font-weights,
    'output': 'standard',
    'property': 'font-weight',
  ),
  'width': (
    'items': (
      '100':'100%',
      '50': '50%',
      'auto': 'auto'
    ),
    'output': 'standard',
    'property': 'width'
  ),
  'max-width': (
    'items': (
      '50': '50%'
    ),
    'output': 'responsive',
    'property': 'max-width'
  ),
  'pointer-events': (
    'items': (
      'none': 'none'
    ),
    'output': 'standard',
    'property': 'pointer-events'
  ),
  'breakpoints': (
    'xs': '(min-width: 25em)',
    'sm': '(min-width: 35em)',
    'md': '(min-width: 55em)',
    'lg': '(min-width: 80em)',
    'xl': '(min-width: 106em)',
  ),
);
