/* Listing Sort By
 *
 * For sortbys on listing pages, styles instantsearch widgets
 * Defaults to look like listing dropdowns
 * Which this file shares a lot of code with.
 * They could be consolidated by rewriting these two classes
 *
 * --plain - removes bg color
 */
.b-listingSortBy {
  --box-padding: 0;
  --box-dark: #{get-color('neutrals-tertiary-400')};
  --box-light: #{get-color('neutrals-primary-500')};
  --select-padding: #{get-space('300')} #{get-space('400')};

  border-radius: $global-radius-base;
  cursor: pointer;

  select {
    appearance: none;
    padding: var(--select-padding);
    padding-inline-end: #{get-space('600')};
    cursor: pointer;

    &::-ms-expand {
      display: none;
    }
  }

  .c-layer {
    --box-padding: var(--select-padding);
    --box-dark: transparent;

    .b-icon {
      color: get-color('neutrals-primary-500');
      font-size: 0.725em;
    }
  }

  &.open {
    & > .b-icon {
      transform: rotate(180deg);
    }
  }

  &--plain {
    --box-dark: transparent;
    --box-light: transparent;
    --select-padding: #{get-space('200')} #{get-space('300')};

    .c-layer {
      .b-icon {
        color: var(--theme-primary);
      }
    }
  }

  &--bigger {
    select {
      @include media-query('lg') {
        font-size: get-text-size('base');
      }
    }
  }
}
