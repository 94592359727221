/**
 * Fonts
 *
 * Import and set your @font-face rules here
 * 
 * #TODO: Franklin Gothic ATF (
 * - 400
 * - 500
 * - 700
 * - 800
 */
// @import url('https://fonts.googleapis.com/css?family=Roboto:400,700');


// Book / 400
// @font-face {
//   font-family: 'Function Pro';
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
//   src: url('/dist/fonts/functionpro-book-webfont.woff2') format('woff2'),
//        url('/dist/fonts/functionpro-book-webfont.woff') format('woff');
// }



// Book Oblique / 400
// @font-face {
//   font-family: 'Function Pro';
//   font-weight: 400;
//   font-style: italic;
//   font-display: swap;
//   src: url('/dist/fonts/functionpro-bookoblique-webfont.woff2') format('woff2'),
//        url('/dist/fonts/functionpro-bookoblique-webfont.woff') format('woff');
// }



// Medium / 500
// @font-face {
//   font-family: 'Function Pro';
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
//   src: url('/dist/fonts/functionpro-medium-webfont.woff2') format('woff2'),
//        url('/dist/fonts/functionpro-medium-webfont.woff') format('woff');
// }



// Medium Oblique / 500
// @font-face {
//   font-family: 'Function Pro';
//   font-weight: 500;
//   font-style: italic;
//   font-display: swap;
//   src: url('/dist/fonts/functionpro-mediumoblique-webfont.woff2') format('woff2'),
//        url('/dist/fonts/functionpro-mediumoblique-webfont.woff') format('woff');
// }



// Demi / 650
// @font-face {
//   font-family: 'Function Pro';
//   font-weight: 650;
//   font-style: normal;
//   font-display: swap;
//   src: url('/dist/fonts/functionpro-demi-webfont.woff2') format('woff2'),
//        url('/dist/fonts/functionpro-demi-webfont.woff') format('woff');
// }



// Demi Oblique / 650
// @font-face {
//   font-family: 'Function Pro';
//   font-weight: 650;
//   font-style: italic;
//   font-display: swap;
//   src: url('/dist/fonts/functionpro-demioblique-webfont.woff2') format('woff2'),
//        url('/dist/fonts/functionpro-demioblique-webfont.woff') format('woff');
// }



// Bold / 700
// @font-face {
//   font-family: 'Function Pro';
//   font-weight: 700;
//   font-style: normal;
//   font-display: swap;
//   src: url('/dist/fonts/functionpro-bold-webfont.woff2') format('woff2'),
//        url('/dist/fonts/functionpro-bold-webfont.woff') format('woff');
// }



// Bold Oblique / 700
// @font-face {
//   font-family: 'Function Pro';
//   font-weight: 700;
//   font-style: italic;
//   font-display: swap;
//   src: url('/dist/fonts/functionpro-boldoblique-webfont.woff2') format('woff2'),
//        url('/dist/fonts/functionpro-boldoblique-webfont.woff') format('woff');
// }
