.u-sticky {
  position: sticky;
}

.u-top {
  @for $i from 1 through 9 {
    &-#{$i}00 {
      top: #{get-space('#{$i}00')}
    }
  }
}

.u-full {
  max-width: none;
  width: 100%;
}