.b-textActionCallout {
  --box-padding: #{get-space('800')} #{get-space('400')};
  --cluster-vertical-alignment: stretch;
  --eyebrow-color: var(--color-grayscale-100);
  --flow-space: #{get-space('200')};
  --gutter: #{get-space('700')};
  --repel-gutter: #{get-space('600')};

  background-color: get-color('grayscale-primary-500');
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 16px;
  

  /**
   * Copy Components
   */
  /*#region copy components */
  .headline {
    font-size: get-text-size('5');
    // margin-bottom: get-space('400');
  }
  .subheadline {
    font-size: get-text-size('2');
  }
  /*#endregion */

  /**
   * Actions container
   */
  /* #region actions */
  & .b-actions {
    flex-shrink: 0;

    a {
      color: get-color('grayscale-primary-100');
      letter-spacing: 1px;
      text-decoration: none;
    }
  }
  /* #endregion*/

  /**
   * Media Queries
   */
  /* #region media queries */
  @include media-query('md') {
    // Reset namespace
    .b-textActionCallout {
      &__copy {
        flex-basis: 35rem;
      }

      &__dividerBar {
        display: block;
        flex-grow: 1;
        height: 1px;
        width: auto;
      }
    }
  }
  /* #endregion */
}
