/// Sass VARIABLES GENERATED WITH DESIGN TOKENS ON 3/6/2025.
/// Tokens location: ../design-system-data/tokens.json

///  COLORS
$gorko-colors: (
  "core-primary-100": #e4eeec,
  "core-primary-200": #69bc7b,
  "core-primary-400": #2cb34a,
  "core-primary-500": #15842d,
  "core-secondary-100": #fffee4,
  "core-secondary-300": #fff875,
  "core-secondary-400": #fff200,
  "core-secondary-500": #e1d600,
  "core-tertiary-100": #ffd493,
  "core-tertiary-300": #ffb443,
  "core-tertiary-400": #ffa013,
  "core-tertiary-500": #eb8c00,
  "neutrals-primary-100": #b59d7c,
  "neutrals-primary-300": #7c6647,
  "neutrals-primary-400": #352a1b,
  "neutrals-primary-500": #130f0a,
  "neutrals-secondary-100": #c7c7c7,
  "neutrals-secondary-300": #a0999a,
  "neutrals-secondary-400": #848484,
  "neutrals-tertiary-100": #fffef3,
  "neutrals-tertiary-300": #fcfbf4,
  "neutrals-tertiary-400": #f6f5e6,
  "neutrals-tertiary-500": #ebe9d5,
  "grayscale-primary-100": #8c8c8c,
  "grayscale-primary-300": #333333,
  "grayscale-primary-400": #222222,
  "grayscale-primary-500": #000000,
  "grayscale-secondary-300": #c7c7c7,
  "grayscale-secondary-400": #bcbcbc,
  "grayscale-secondary-500": #b0b0b0,
  "grayscale-tertiary-400": #ffffff,
  "state-good": #439750,
  "state-good-100": #e6f2ed,
  "state-good-300": #66d19e,
  "state-info": #d3bc40,
  "state-warn": #c76868,
  "state-warn-100": #ffefed,
  "state-warn-300": #f1998e,
  "state-bad": #e11900,
  "backgrounds-checkbox":
    url("data:image/svg+xml,%3Csvg viewBox='0 0 18 18' fill='%23191919' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16 2V16H2V2H16ZM16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0Z' /%3E%3C/svg%3E"),
  "backgrounds-checkbox-checked":
    url("data:image/svg+xml,%3Csvg viewBox='0 0 18 18' fill='%233740ff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM16 16H2V2H16V16ZM15 6L13.6 4.6L7 11.2L4.4 8.6L3 10L7 14L15 6Z' /%3E%3C/svg%3E"),
  "backgrounds-checkbox-dark":
    url("data:image/svg+xml,%3Csvg viewBox='0 0 18 18' fill='%23f6f6f7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16 2V16H2V2H16ZM16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0Z' /%3E%3C/svg%3E"),
  "backgrounds-checkbox-dark-checked":
    url("data:image/svg+xml,%3Csvg viewBox='0 0 18 18' fill='%239da2ff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM16 16H2V2H16V16ZM15 6L13.6 4.6L7 11.2L4.4 8.6L3 10L7 14L15 6Z' /%3E%3C/svg%3E"),
  "backgrounds-radio":
    url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23191919' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z' /%3E%3C/svg%3E"),
  "backgrounds-radio-checked":
    url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%233740ff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7Z' /%3E%3C/svg%3E"),
  "backgrounds-radio-dark":
    url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f6f6f7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z' /%3E%3C/svg%3E"),
  "backgrounds-radio-dark-checked":
    url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%239da2ff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7Z' /%3E%3C/svg%3E"),
  "highlights-magenta": #ff00b8,
  "highlights-green": #128500,
  "highlights-purple": #7600ae,
);

///  TEXT SIZES
$gorko-size-scale: (
  "base": 1rem,
  "00": clamp(0.625rem, calc(0.59rem + 0.18vw), 0.75rem),
  "0": clamp(0.8125rem, calc(0.79rem + 0.09vw), 0.875rem),
  "1": clamp(1rem, calc(0.96rem + 0.18vw), 1.125rem),
  "2": clamp(1.25rem, calc(1.2rem + 0.27vw), 1.4375rem),
  "3": clamp(1.5625rem, calc(1.51rem + 0.27vw), 1.75rem),
  "4": clamp(1.9375rem, calc(1.87rem + 0.36vw), 2.1875rem),
  "5": clamp(2.4375rem, calc(2.35rem + 0.45vw), 2.75rem),
  "6": clamp(3.0625rem, calc(2.96rem + 0.54vw), 3.4375rem),
  "7": clamp(3.8125rem, calc(3.67rem + 0.71vw), 4.3125rem),
  "8": clamp(4.75rem, calc(4.57rem + 0.89vw), 5.375rem),
);

///  SPACING SIZES
$gorko-space-scale: (
  "base": 1rem,
  "0": 0rem,
  "100": clamp(0.25rem, calc(0.23rem + 0.09vw), 0.3125rem),
  "200": clamp(0.5rem, calc(0.48rem + 0.09vw), 0.5625rem),
  "300": clamp(0.75rem, calc(0.71rem + 0.18vw), 0.875rem),
  "400": clamp(1rem, calc(0.96rem + 0.18vw), 1.125rem),
  "500": clamp(1.5rem, calc(1.45rem + 0.27vw), 1.6875rem),
  "600": clamp(2rem, calc(1.93rem + 0.36vw), 2.25rem),
  "700": clamp(3rem, calc(2.89rem + 0.54vw), 3.375rem),
  "800": clamp(4rem, calc(3.86rem + 0.71vw), 4.5rem),
  "900": clamp(6rem, calc(5.79rem + 1.07vw), 6.75rem),
);

///  FONTS
$gorko-fonts: (
  "base": "franklin-gothic-atf,Segoe UI,system-ui,-apple-system,sans-serif",
  "brand": "franklin-gothic-atf,Segoe UI,system-ui,-apple-system,sans-serif",
);

///  FONT WEIGHTS
$gorko-font-weights: (
  "thin": 100,
  "extra-light": 200,
  "light": 300,
  "regular": 400,
  "medium": 500,
  "demi": 600,
  "bold": 700,
  "extra-bold": 800,
  "black": 900,
  "extra-black": 950,
);

///  MISC
$global-radius-base: 2px;
$global-radius-large: 10px;
$global-radius-full: 100vh;
$global-transition-base: 250ms ease;
$global-transition-base-duration: 250ms;
$global-transition-movement: 200ms linear;
$global-transition-movement-duration: 200ms;
$global-transition-fade: 400ms ease;
$global-transition-fade-duration: 400ms;
$global-transition-bounce: 500ms cubic-bezier(0.45, 0.05, 0.22, 1.3);
