.simpleCardCarousel {
  overflow: hidden;
  &__headline {
    letter-spacing: -1px;
  }

  &__slider {
    margin-top: get-space('800');
    overflow: visible;
  }

  &__grid {
    gap: 40px;
    margin-top: get-space('800');

    &--1 {
      .b-simpleCard--square {
        width: 100%;
        height: 270px;
      }
    }

    &--2 {
      .b-simpleCard--square {
        flex: 1 0 48%;
        height: 270px;
      }
    }

    &--3 {
      .b-simpleCard--square {
        flex: 1 0 20%;
        height: 270px;
      }
    }
  }

  &__with-controls {
    .simpleCardCarousel__slider {
      margin-top: get-space('900');
    }
  }

  @include media-query('md') {
    &__content.c-switcher > * {
      flex: 0 0 auto;
    }

    &__heading {
      max-width: 47%;
    }

    &__copy {
      justify-content: flex-end;
      margin-left: auto;
      max-width: 39%;
    }
  }
}
