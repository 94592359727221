/** CENTER
 * @see EL access: https://api.every-layout.dev/view?email=matt@inlikealion.com
 * @see https://every-layout.dev/layouts/center/
 * A simple layout component for centering contents
 * The max-width is set to ch to ensure readibility

 * CUSTOM PROPERTIES AND CONFIGURATION
 * --measure (60ch): The max width of the centered content
 */

.c-center {
  box-sizing: content-box;
  margin-inline: auto;
  max-inline-size: var(--measure, $global-measure);
  padding-inline-start: var(--center-padding-start, 0);
  padding-inline-end: var(--center-padding-end, 0);

  &--intrinsic {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &--measure-70 {
    --measure: 70ch;
  }
}
