/// BUTTON BASE STYLES
/// Consistent, base styles that can be shared
/// between button-type elements
@mixin button-base-styles() {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: get-text-size('2');
  text-decoration: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: background-color $global-transition-base, border $global-transition-base;
  text-transform: uppercase;
  font-weight: bold;

  /// It's really important line-height is flat
  /// so to prevent cascade issues, we make it important
  // line-height: 1 !important;
  line-height: 0.8888888889 !important;

  // &,
  // &:visited {
  //   @include apply-utility('color', 'action-text');
  // }
  @include apply-utility('bg', 'action-bg');
  @include apply-utility('weight', 'medium');
  @include apply-utility('font', 'brand');
  @include vertically-align-label();

  // &,
  // &:visited {
  //   @include apply-utility('color', 'action-text');
  // }

  /// If there's no text utility, we need to make sure
  /// the& is the base size as internal padding
  /// is em based
  // &:not([class*='text']) {
    font-size: get-text-size('0');
  // }

  /// DISABLED STATE
  /// All properties are !important because this
  /// state has to take priority in all cases
  &.isDisabled,
  &:disabled {
    filter: invert(0.2) !important;
    box-shadow: none !important;
    transform: none !important;
    cursor: not-allowed !important;
  }
}
