// This can eventually replace the photo pill within the basic card as well
.b-pill {
  --box-light: #{get-color('neutrals-tertiary-500')};
  --box-dark: #{get-color("neutrals-primary-500")};

  border-radius: $global-radius-base;
  line-height: 0.5;

  .b-icon {
    color: #D9D9D9;
  }
}