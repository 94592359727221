/**
 * ICON BLOCK
 *
 * A fixed width icon
 *
 * @see EL access: https://api.every-layout.dev/view?email=matt@inlikealion.com
 * @see https://every-layout.dev/layouts/icon/
 */

.b-icon {
  $default: .75em;
  width: var(--icon-size, $default);
  height: var(--icon-size, $default);
  // Sean: Don't want to turn this on yet, but this seems like a good
  // set up for future use cases of an icon class
  // setting both color and fill to cover different use cases
  // Another possibility could be to create an exception for each
  // color controlling property
  // color: var(--icon-color, inherit);
  // fill: var(--icon-color, inherit);
}