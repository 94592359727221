// Styles for ClickDimensions elements
#modal-overlay {
    background: rgba(255,255,255,0.6);
    z-index: 999999 !important;
}

#modalMain {
    div {
        width: 100% !important;
        text-align: center;
    }

    #trackMe {
        cursor: pointer;
        border-radius: 2px;
    }
}