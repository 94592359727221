/**
 * WITH-ICON COMPOSITION
 *
 * Used on a parent with icon & text to align them both properly
 *
 * @see https://api.every-layout.dev/view?email=matt@inlikealion.com
 * @see https://every-layout.dev/layouts/icon/
 *
 * CUSTOM PROPERTIES AND CONFIGURATION
 * --icon-spacing (): Controls the space between the icon and it's corresponding text
 */

.c-withIcon {
  display: inline-flex;
  align-items: baseline;

  .b-icon:first-child {
    margin-inline-end: var(--icon-spacing, 1em);
  }

  .b-icon:last-child {
    margin-inline-start: var(--icon-spacing, 1em);
  }

  &--icon-spacing-half {
    --icon-spacing: .5em;
  }
}
