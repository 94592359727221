/**
 * Global HEADER Block
 */
.b-header {
  // As long as mobile nav height (top bar) is consistent
  // we don't need js (for mobile nav)
  $mobileNavHeight: 80px;

  --nav-top-background-color: #{get-color('neutrals-tertiary-400')};
  --nav-background-color: #{get-color('neutrals-tertiary-100')};

  background-color: var(--nav-background-color);
  box-shadow: inset 0 -1px 0 get-color('neutrals-tertiary-400');
  font-size: 1rem;
  position: relative;
  z-index: 99999;

  & [data-account-link] {
    display: none;
  }

  &--mobileNavActive {
    // reset namespace
    .b-header {
      &__navsWrapper {
        height: calc(100vh - #{$mobileNavHeight});
        opacity: 1;
        overflow-y: scroll;
        pointer-events: all;
      }

      &__mobileTrigger {
        // Span animations
        & span[class*='top'] {
          transform: rotate(-45deg) translate(-5px, 5px);
        }
        & span[class*='middle'] {
          transform: scaleX(0);
        }
        & span[class*='bottom'] {
          transform: rotate(45deg) translate(-5px, -5px);
        }
      }
    }
  }

  &__logo {
    min-height: 50px;
    max-height: 80px;
    width: auto;

    img {
      object-fit: contain;
      object-position: 0;
      min-height: 50px;
      max-height: 85px;
      width: 100%;
    }
  }

  &__mobileTrigger {
    appearance: none;
    background-color: get-color('neutrals-tertiary-400');
    border: none;
    border-radius: $global-radius-base;
    cursor: pointer;
    display: none;
    height: 2.5625em;
    margin-left: auto;
    padding: 0;
    width: 2.5625em;
    z-index: 10000;

    &__bars {
      height: 1em;
      width: 1.2em;
    }

    & span {
      border-radius: 0.5px;
      height: 2px;
      width: 100%;

      &[class*='top'],
      &[class*='bottom'] {
        transform-origin: 50%;
        transition: all $global-transition-base;
      }

      &[class*='middle'] {
        transform-origin: 100%;
        transition: all $global-transition-base;
      }
    }
  }

  &__primaryCta {
    --pad-vert: #{get-space('400')};
    --pad-horiz: #{get-space('400')};
  }

  &__navsWrapper {
    @include absolute($top: 100%, $left: 0);
    background-color: var(--nav-background-color);
    height: 100vh;
    opacity: 0;
    padding-bottom: 10vh;
    pointer-events: none;
    transition: opacity $global-transition-base;
    width: 100%;
  }

  &__multisiteNav {
    font-size: 0.65em;
    letter-spacing: 0.0625em;
    overflow-x: scroll;
    overflow-y: hidden;
    max-width: 100%;
    white-space: nowrap;
    width: 100%;

    & a {
      @include apply-utility('box', 'flex');
      @include apply-utility('pad-block', '300');
      @include apply-utility('pad-inline', '400');

      &:hover {
        color: var(--theme-primary);
      }
    }

    & a.active {
      @include apply-utility('weight', 'bold');
      background-color: var(--nav-background-color);
      color: var(--theme-primary);
    }

    // Match "lg" breakpoint value (- 1em)
    @media only screen and (max-width: 79em) {
      /* width */
      &::-webkit-scrollbar {
        height: 1px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: get-color('neutrals-tertiary-500');
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: get-color('core-primary-500');
      }
    }
  }

  &__utilityNav {
    & a {
      @include apply-utility('gap-inline', '500');
      @include apply-utility('pad-block', '200');

      &:hover {
        color: var(--theme-primary);
      }
    }
  }

  &__primaryNav {
    &__linksList {
      &__item {
        border-bottom: 2px solid
          transparentize(get-color('neutrals-primary-500'), 0.95);
        flex-direction: column;
        color: get-color('neutrals-primary-500');

        &.active {
          padding-bottom: get-space('500');

          & > .b-header__primaryNav__linksList__item__subnav {
            height: auto;
            transform: scaleY(1);
            transition: transform $global-transition-base;
          }

          .b-header__primaryNav__linksList__item__anchor {
            .b-icon {
              transform: rotate(0deg);
            }
          }
        }

        // Individual Site Navigation Link
        &__anchor {
          position: relative;
          font-weight: 500;
          width: 100%;

          // Text span
          & > span:first-of-type {
            margin-right: auto;
          }

          .b-icon {
            color: get-color('neutrals-primary-100');
          }

          &:hover {
            & .b-icon {
              color: var(--theme-primary);
            }
          }

          & .b-icon {
            transform: rotate(-180deg);
            transition: all $global-transition-base;
          }
        }

        &__subnav {
          --switcher-gutter: 0;

          @include apply-utility('weight', 'regular');
          height: 0;
          transform: scaleY(0);
          transform-origin: center top;

          &__list {
            margin-bottom: get-space('400');
            padding-left: get-space('500');

            &__item {
              // Make the link relative so hoverContent is side-by-side
              & > a {
                display: inline-flex;
                position: relative;
              }
            }

            @include media-query('lg') {
              max-width: 82%;
            }
          }

          .b-featuredNavigationLink {
            --box-padding: #{get-space('700')};

            overflow: hidden;

            a {
              height: 100%;
            }

            img {
              pointer-events: none;
            }

            .b-featuredNavigationLink__content {
              --box-padding: #{get-space('400')};
              --icon-size: 1.25rem;
              // Reveal Props - reveal props need to exist here to correctly pass component specific variables
              --content-size: var(--icon-size);
              --item-reveal-padding: var(--box-padding);

              .b-icon {
                color: get-color('grayscale-tertiary-400');
              }

              @include media-query('lg') {
                --icon-size: 2rem;
              }
            }

            // Under MD manually break to full width
            @media only screen and (max-width: 879.99px) {
              flex-basis: 100%;
            }

            @include media-query('lg') {
              --box-padding: #{get-space('400')} #{get-space('700')} #{get-space(
                  '700'
                )} #{get-space('700')};
              height: 100%;
            }
          }
        }
      }
    }
  }

  @include media-query('lg') {
    box-shadow: none;

    // Reset namespace
    .b-header {
      &__navsWrapper {
        background-color: transparent;
        height: auto;
        left: 0;
        opacity: 1;
        padding-bottom: 0;
        pointer-events: all;
        position: relative;
        top: 0;

        & > .c-wrapper {
          position: unset;
        }
      }

      &__multisiteNav {
        height: 40px;
        overflow: visible;
        position: relative;
        width: auto;

        &::after {
          @include pseudo;
          background-color: var(--nav-top-background-color);
          height: 40px;
          left: -100vw;
          position: absolute;
          top: 0;
          width: 300vw;
          z-index: -1;
        }
      }

      &__utilityNav {
        & a {
          @include apply-utility('gap-inline', '0');
          @include apply-utility('gap-left', '500');
        }
      }

      &__primaryNav {
        &__linksList {
          &__item {
            border: none;
            flex-direction: row;

            & > a:hover,
            & > a:focus,
            & > a:active {
              color: var(--theme-primary);

              & + .b-header__primaryNav__linksList__item__subnav {
                opacity: 1;
                pointer-events: all;
                z-index: unset;
              }
            }

            &__anchor {
              width: auto;

              & > span:first-of-type {
                margin-right: get-space('300');
              }
            }

            &__subnav {
              @include absolute($top: 100%, $left: 0);
              background-color: get-color('neutrals-tertiary-100');
              height: auto;
              opacity: 0;
              pointer-events: none;
              transform: scaleY(1);
              transition: opacity $global-transition-base;
              width: 100%;
              z-index: 0;

              &:hover,
              &:focus-within {
                opacity: 1;
                pointer-events: all;
              }

              & > .c-wrapper {
                height: 450px;
                position: unset; // dont want featuredLink relative to this
              }

              &__list {
                display: grid;
                grid-auto-flow: row dense;
                grid-template-columns: 1fr 1fr;
                margin-bottom: get-space('700');
                // margin-top: get-space("700");
                padding-left: 0;
                position: relative;
                min-width: 50%;

                &__item {
                  &.item--column1 {
                    grid-column: 1 / span 1;
                  }

                  &.item--column2 {
                    grid-column: 2 / span 1;
                  }

                  & > a {
                    padding-bottom: get-space('200');
                    padding-top: get-space('200');

                    &:focus {
                      outline: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

[data-handle='huntingLeaseNetwork'] .b-header {
  --nav-top-background-color: #{get-color('neutrals-primary-500')};
  --nav-background-color: #{get-color('neutrals-primary-400')};

  a:not(.b-button) {
    color: get-color('neutrals-tertiary-500');

    &.active,
    &:hover {
      color: var(--theme-primary);
    }
  }

  &__multisiteNav,
  &__utilityNav {
    a {
      color: #{get-color('neutrals-primary-100')};
    }
  }
}
