.u-hoverTarget-simpleReveal {
  .u-hoverEffect-simpleReveal {
    transition: transform .3s ease-in-out;
    
    &--top {
      transform: translateY(-100%);
    }
  
    &--right {
      transform: translateX(100%);
    }
  
    &--bottom {
      transform: translateY(100%);
    }
  
    &--left {
      transform: translateX(-100%);
    }
  }
  
  &:focus,
  &:hover {
    .u-hoverEffect-simpleReveal {
      transform: translate(0);
    }
  }
}

@media only screen and (max-width: 879.99px) {
  .max-md\:u-hoverTarget-simpleReveal {
    .u-hoverEffect-simpleReveal {
      transform: translate(0);
    }
  }
}

@media only screen and (max-width: 1279.99px) {
  .max-lg\:u-hoverTarget-simpleReveal {
    .u-hoverEffect-simpleReveal {
      transform: translate(0);
    }
  }
}