// After thinking on it more hover target and hover effect seem like good terms.
// Where hover target would be the thing that triggers the hover effect
// And hover effect is the hover "animation" or change
@mixin grungeBg($asHover: 0) {
  @supports (mix-blend-mode: screen) {
    position: relative;
    $activeOpacity: 0.6;

    &::before {
      @include absolute($top: 0, $left: 0);
      @include pseudo;
      background-blend-mode: screen;
      background-image: url('/dist/img/bg-texture-grunge.jpg');
      background-position: center right;
      background-size: 400px;
      height: 100%;
      mix-blend-mode: screen;
      opacity: $activeOpacity;
      width: 100%;
      z-index: 0;
      pointer-events: none;
    }

    @if $asHover == 1 {
      &::before {
        opacity: 0;
        transition: opacity .3s ease-in-out;
      }

      &:focus,
      &:hover {
        &::before {
          opacity: $activeOpacity;
        }
      }
    }
  }
}

@mixin grungeText() {
  @supports (mix-blend-mode: screen) {
    $grunge-color-default: get-color('grayscale-tertiary-400');
    $grunge-color-rgba: rgba($grunge-color-default, 0.85);

    --grunge-text-opacity: 0.85;
    --grunge-color: #{$grunge-color-default};
    --grunge-color-rgba: #{$grunge-color-rgba};

    color: var(--grunge-color);
    position: relative;
    background-image: url('/dist/img/bg-texture-grunge.jpg');
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--grunge-color-rgba);

    // Manually adding for now, could be within a loop
    &--primary { // core-tertiary-400 || white
      $grunge-color: get-color('core-primary-500');
      $grunge-color-rgba: rgba($grunge-color, 0.85);
      --grunge-color: #{$grunge-color};
      --grunge-color-rgba: #{$grunge-color-rgba};
    }

    &--neutrals-tertiary-500 {
      $grunge-color: get-color('neutrals-tertiary-500');
      $grunge-color-rgba: rgba($grunge-color, 0.85);
      --grunge-color: #{$grunge-color};
      --grunge-color-rgba: #{$grunge-color-rgba};
    }
  }
}

.u-bg-grunge {
  @include grungeBg(0);
}

.u-bg-grungeHover {
  @include grungeBg(1);
}

.u-text-grunge {
  @include grungeText;  
}

// TODO: update naming convention to match shift, although that get's a little tricker
// since this one is specifically targeting text. Not sure whether text or hoverTarget
// should take precendence first
.u-text-grungeHoverTarget {
  transition: color .3s ease-in-out;
}

.u-text-grungeHover {
  &:hover {
    .u-text-grungeHoverTarget {
      @include grungeText;
    }
  }
}
