.b-propertyCard {
  margin-top: get-space('400');

  &__title {
    font-size: get-text-size('0');
    letter-spacing: 2px;
    line-height: 18px;
    text-transform: uppercase;
  }

  &__subtitle {
    margin-top: get-space('300');
    font-size: get-text-size('2');
    line-height: 32px;
    letter-spacing: -1px;
  }

  &__heading {
    width: 100%;
    margin-top: get-space('200');
    &__location {
      flex-grow: 1;
      font-size: get-text-size('0');
      letter-spacing: 0.01em;
      line-height: 28px;
      &--truncate {
        display: -webkit-box;
        -webkit-line-clamp: var(--line-clamp, 3);
        -webkit-box-orient: vertical;
        overflow: hidden;
        hyphens: auto;
      }
    }

    &__status {
      align-items: center;
      &__text {
        font-size: get-text-size('0');
        letter-spacing: 2px;
        line-height: 18px;
        text-transform: uppercase;
      }

      &--active {
        align-self: center;
        background-color: get-color('core-primary-500');
        border-radius: 50%;
        display: inline-block;
        height: 12px;
        margin-right: get-space('200');
        width: 12px;
      }
    }
  }

  &__info {
    font-size: .875em;
    flex-grow: 1;
    align-self: center;

    &__tract {
      font-size: get-text-size('0');
      letter-spacing: 0.01em;
      line-height: 28px;
      margin-right: get-space('200');
    }

    &__size > span {
      font-size: get-text-size('0');
      letter-spacing: 0.01em;
      line-height: 28px;
    }
    
    &__time {
      font-size: get-text-size('0');
      letter-spacing: 0.01em;
      line-height: 32px;
      margin-left: get-space('100');
      color: get-color('neutrals-primary-300');
    }
  }

  @include media-query('sm') {
    flex: 0 1 100%;
  }

  @include media-query('lg') {
    flex: 0 1 33.3%;
  }
}
