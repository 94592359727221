.b-propertyDetailSidebar {
  &__list {
    list-style: none;
  }

  &__location {
    max-width: 150px;
  }

  &__eyebrow {
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  &__copy {
    max-width: 35ch;
  }
}
