@mixin eyebrow {
  --eyebrow-green-bg: #{rgba(get-color('core-primary-500'), 0.9)};
  --eyebrow-orange-bg: #{rgba(get-color('core-tertiary-500'), 0.9)};
  background-color: var(--eyebrow-bg-color, get-color('neutrals-tertiary-400'));
  color: var(--eyebrow-color, get-color('core-primary-500'));
  letter-spacing: 0.125em;
  padding-block: 0.625em;
  padding-inline: 1.25em;
  width: fit-content;

  &--clear {
    padding: 0;
    background-color: transparent;

    &::after,
    &::before {
      content: none;
    }
  }

  &--primary {
    --eyebrow-bg-color: var(--eyebrow-green-bg);

    &.eyebrow--huntingLeaseNetwork {
      --eyebrow-bg-color: var(--eyebrow-orange-bg);
    }
  }

  &--secondary {
    --eyebrow-bg-color: var(--eyebrow-orange-bg);
  }

  &--huntingLeaseNetwork {
    &:not(.eyebrow--primary) {
      span {
        color: var(--theme-primary);
      }
    }
  }

  // Default was set to box style, feels as though box
  // should be the exception. Most likely more work.
  // &--box {
  //   padding-block: 0.625em;
  //   padding-inline: 1.25em;
  //   width: fit-content;
  // }
}

.eyebrow {
  @include eyebrow;
}
