/**
 * COMPONENT LIBRARY LOCATION
 * https://web.dev/design-system/component/button
 *
 * SIZES:
 *  Buttons (`.b-button`) are roughly 40px tall,
 *  Large buttons (`.b-button.b-button--large`) are roughly 50px tall
 *
 * TYPES:
 *  Default (`.b-button`)
 *  Primary (`.b-button.b-button--primary`)
 *  Secondary (`.b-button.b-button--secondary`)
 *  Wipe (`.b-button.b-button--wipe`)
 *  Normal Icon (`.b-button.b-button--with-icon.c-withIcon`)
 *    (Mix with primary or secondary classes as needed)
 *
 * STATES:
 *  (`button:disabled`) darkens each button style and
 *

 * EXAMPLE MARKUP:

  // SIMPLE
  <a href="#" class="b-button">{{ v }} Link Button</a>

  // COMPLEX
  <a href="#" class="b-button  b-button--primary  b-button--with-icon | c-withIcon">
      <svg class="b-icon"><use href="/svg/sprite.svg#arrow-right"></use></svg>
      <span class="b-button__text">Primary Link Button</span>
  </a>

  // WIPE Animation
  <button class="b-button  b-button--wipe">
      <svg class="b-icon"><use href="/svg/sprite.svg#arrow-right"></use></svg>
      <span class="b-button__text">Wipe Button</span>
  </button>

 */

/*
 * Transparent hover mixin for handling transparent exception on other exceptions
 * $coreColor: string - One of the core colors ex: primary, secondary, tertiary
 */
@mixin clearHover($coreColor) {
  &.b-button--clearHover {
    .b-icon {
      transform: translateX(0);
      transition: $global-transition-base;
    }

    &:focus,
    &:focus-visible,
    &:hover {
      color: get-color('#{$coreColor}');

      .b-icon {
        &:first-child {
          transform: translateX(-50%);
        }

        &:last-child {
          transform: translateX(50%);
        }
      }
    }
  }
}

/*
 * Core button mixin for handling main button styles
 * $coreColor: string - One of the core colors ex: primary, secondary, tertiary
 */
@mixin coreButton($coreColor, $hoverColor, $textColor: '', $iconColor: '') {
  background-color: get-color('#{$coreColor}');
  border-color: get-color('#{$coreColor}');

  @if $iconColor != '' {
    &:not(.b-button--clear) {
      .b-icon {
        color: get-color($iconColor);
      }
    }
  }

  &:not(.b-button--clearHover) {
    @include grungeBg(1);

    &.b-button--with-icon {
      // Default Icon size .75em + .5em (margin);
      span {
        transform: translateX(calc(1.25em / 2));
        transition: $global-transition-base;
      }

      .b-icon {
        opacity: 0;
        transform: translateX(-1.25em);
        transition: $global-transition-base;
      }

      &:focus,
      &:hover {
        background-color: get-color('#{$hoverColor}');
        border-color: get-color('#{$hoverColor}');

        span {
          transform: translateX(0);
        }

        .b-icon {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }

    @if $textColor != '' {
      color: get-color($textColor);
    }

    @include clearHover($coreColor);
  }
}

/*
 * Clear hover button mixin for handling hover styles of the clear exception
 * $color: string - One of the core colors ex: primary, secondary, tertiary
 */
@mixin clearButton($color) {
  color: get-color('#{$color}');

  &:not(.b-button--clearHover) {
    &:focus,
    &:focus-visible,
    &:hover {
      background-color: get-color('#{$color}');
      border-color: get-color('#{$color}');
    }
  }

  &.b-button--clearHover {
    overflow: visible;
    padding-inline: 0;
  }

  @include clearHover($color);
}

/**
 * DEFAULT
 */
.b-button {
  // These could be setup as their own tokens filled by other color type tokens.
  // This ties into Abry's ask on setting colors through the CMS.
  $primaryBtnColor: 'core-primary-500';
  $secondaryBtnColor: 'core-tertiary-400';
  $tertiaryBtnColor: 'neutrals-primary-300';
  $darkBtnColor: 'grayscale-primary-500';
  $lightBtnColor: 'neutrals-tertiary-100';
  // --pad-vert: 0.6666666667em; // 12px / 18px
  // --pad-horiz: 1em; // 18px
  --pad-vert: #{get-space('400')};
  --pad-horiz: #{get-space('500')};

  color: get-color('grayscale-primary-100');
  letter-spacing: 0.1ch;
  border-radius: $global-radius-base;
  padding: var(--pad-vert) var(--pad-horiz);

  @include button-base-styles();
  font-weight: 800;

  &:focus,
  &:hover {
    background-color: $primaryBtnColor;
    border-color: $primaryBtnColor;
    color: get-color('grayscale-tertiary-400');
  }

  /**
   * CORE VARIANTS
   */
  &--primary,
  &--secondary {
    &,
    &:visited {
      color: get-color('neutrals-tertiary-100');
    }
  }

  /**
   * PRIMARY
   */
  &--primary {
    @include coreButton(
      $primaryBtnColor,
      $primaryBtnColor,
      '',
      'core-secondary-400'
    );
  }

  /**
   * SECONDARY
   */
  &--secondary {
    @include coreButton(
      $secondaryBtnColor,
      $secondaryBtnColor,
      '',
      'neutrals-primary-500'
    );
  }

  /**
   * TERTIARY
   */
  &--tertiary {
    @include coreButton(
      $tertiaryBtnColor,
      $tertiaryBtnColor,
      '',
      'neutrals-primary-500'
    );
  }

  /**
   * DARK
   */
  &--dark {
    @include coreButton(
      $darkBtnColor,
      $darkBtnColor,
      'grayscale-tertiary-400',
      'neutrals-primary-500'
    );
  }

  /**
   * LIGHT
   */
  &--light {
    @include coreButton(
      $lightBtnColor,
      $lightBtnColor,
      'grayscale-primary-500',
      'neutrals-primary-500'
    );
  }

  /**
   * CLEAR button excpetion
   *  May be paired with .b-button--primary or .b-button--secondary classes
   */
  &--clear {
    border-color: transparent;
    background-color: transparent;

    &.b-button {
      &--primary {
        @include clearButton($primaryBtnColor);
      }

      &--secondary {
        @include clearButton($secondaryBtnColor);
      }

      &--tertiary {
        @include clearButton($tertiaryBtnColor);
      }

      &--dark {
        @include clearButton($darkBtnColor);
      }

      &--light {
        @include clearButton($lightBtnColor);
      }
    }

    &:focus,
    &:hover {
      color: get-color('grayscale-tertiary-400');
    }
  }

  /**
   * With Icon excpetion
   *  Icon markup can be at begining or end of button
   *  Don't forget the c-withIcon composition class on these
   */
  &--with-icon {
    --icon-spacing: 0.5em;
  }

  /**
   * CLEAR HOVER button exception
   * Pairs with core & clear types (--primary, --secondary, --tertiary)
   * --clear defines default state, --clear hover defines hover/focus state
   */
  &--clearHover {
    // font-weight: 400;

    &:focus,
    &:focus-visible,
    &:hover {
      background-color: transparent;
      border-color: transparent;
    }
  }

  /**
   * LARGE exception
   * increases padding to make roughly 50px tall buttons
   */
  &--large {
    --pad-vert: #{get-space('500')};
    --pad-horiz: #{get-space('600')};
    // font-size: get-size('1');
  }
}
