.b-modal { 
  border-radius: $global-radius-base;
  display: none;
  height: 100%;
  left: 0;
  margin-block-start: 0;
  max-height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;

  &__body {
    --wrapper-max-width: 37.5rem;
    --wrapper-width: #{clamp(16rem, 95vw, 37.5rem)};
    box-shadow: 0px 0px 4px rgba(0,0,0,.2);
    align-items: center;
    border-radius: 3px;
    display: flex;

    > .c-wrapper {
      background-color: get-color('neutrals-tertiary-100');
    }
  }

  &__noBody {
    > div > .c-box {
      width: 100%;
    }
  }

  .b-closeBtn {
    --icon-size: 2.5rem;
  }

  &--open {
    display: block;
  }

  &__loginForm {
    label {
      display: none;
    }

    input {
      --box-padding: unset;
      display: block;
      width: 100%;
      background-color: get-color('neutrals-tertiary-400');
      border-radius: $global-radius-base;
    }
  }
}
