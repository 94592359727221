// Accessibility utilities
.u-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.u-sr-only-focusable:focus {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: auto;
  height: auto;
  margin: 0;
  color: #FFFFFF;
  background-color: #79AEDE;
  clip: inherit;
}