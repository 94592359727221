.hero {
  .b-button {
    --pad-vert: #{get-space('400')};
    --pad-hor: #{get-space('500')};
  }

  // background-color: get-color("grayscale-400");
  background-position: center;
  background-size: cover;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;

  .c-wrapper {
    margin-block-start: auto;
    max-width: 100%;
    z-index: 2;
  }

  // Applied to heros on homepages across the sites
  &--homepage {
    // Mobile: 520 / 375 = 138.66666667%
    // Desktop: 700 / 1440 = 48.61111111%
    --min-height: clamp(32.5rem, calc(29.29rem + 16.07vw), 43.75rem);

    &::before {
      @include absolute(0, 0);
      @include pseudo;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) -74.15%,
        rgba(0, 0, 0, 0) 100%
      );
      height: 100%;
      width: 100%;
      z-index: 1;
    }
  }

  // Wrapper for video el
  &__video {
    @include absolute(0, 0);
    height: 100%;
    width: 100%;

    .c-frame {
      min-height: 100%;
      min-width: 100%;
      @include absoluteCenterX;
    }

    iframe,
    video {
      object-fit: cover;
      min-width: 100%;
    }

    iframe {
      box-sizing: border-box;
      min-width: 100%;
      min-height: 100%;
    }
  }

  &__tightContent {
    max-width: 54ch;
  }

  &__eyebrow {
    letter-spacing: 0.3125em;
    opacity: 0.8;
  }

  .c-frame {
    overflow: hidden;
    width: 100%;
    /* Keep it the right aspect-ratio */
    aspect-ratio: 16/9;
    /* No clicking/hover effects */
    pointer-events: none;
  }

  .c-frame iframe {
    /* Extend it beyond the viewport... */
    width: 300%;
    height: 100%;
    /* ...and bring it back again */
    margin-left: -100%;
  }

  .headline {
    line-height: 1.1;
    font-size: get-text-size('5');

    @include media-query('md') {
      font-size: get-text-size('6');
    }
  }

  &__headline {
    font-size: get-text-size('6');
    // @include apply-utility("weight", "semi-bold");
  }

  &__properties {
    margin-top: get-space('600');
  }

  &--main {
    // .c-flow {
    // 	max-width: 48ch;
    // }

    @include media-query('md') {
      .headline {
        font-size: get-text-size('6');
      }
    }
  }

  &--search {
    --flow-space: #{get-space('500')};
    padding-bottom: 3rem;

		.b-listingDropdown__content, .b-listingDropdown__caret {
			display: none !important;
		}

		#heroSearchBarWrapper {
			--icon-color: #{get-color('core-primary-500')};
			width: 100%;
			max-width: 37.5rem;

      .b-listingDropdown__select > div {
        width: 100%;
      }

      svg {
        fill: currentColor;
      }

      #listingMainSearchBar {
        flex-basis: unset;
        // color: get-color('core-primary');

        .ais-SearchBox-submit {
          display: flex;
          align-items: center;

          svg {
            width: 1rem;
            height: 1rem;
          }
        }

        input {
          font-size: 1.125em;
        }
      }
    }
  }

  &--standard {
    background-color: get-color('neutrals-primary-300');
    // Design has height at 406px which is 25.375rem
    --min-height: clamp(21.375rem, calc(29.29rem + 16.07vw), 25.375rem);

    &::before {
      @include absolute(0, 0);
      @include pseudo;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) -74.15%,
        rgba(0, 0, 0, 0) 100%
      );
      height: 100%;
      width: 100%;
      z-index: 1;
    }
  }

  &__imageBelow {
    display: flex;
    flex-direction: column;

    .c-frame {
      order: 1;
      @media only screen and (max-width: 799.95px) {
        margin-top: 0;
      }
    }

    &__content {
      order: 2;
    }
  }

  @include media-query('md') {
    padding-bottom: 115px;

    &__headline {
      font-size: get-text-size('8');
    }

    &__content,
    &__properties {
      width: 50%;
    }

    &__properties {
      margin-top: 0px;
    }

    &--search {
      padding-bottom: 115px;

      .hero__content {
        width: 100%;
        max-width: 55rem;
        margin: 0 auto;
      }

      .search-bar-simple {
        max-width: 1200px;
      }
    }
  }
}
