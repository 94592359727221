.b-propertyCardCarousel {
  &__heading {
    letter-spacing: -1px;
  }

  &__slider {
    margin-top: get-space('800');
    overflow: visible;
  }

  &__carouselWrapper {
    position: relative;
  }

  .swiper {
    width: 100%;
  }

  @include media-query('md') {
    &__content.c-switcher > * {
      flex: 0 0 auto;
    }

    &__heading {
      max-width: 47%;
    }

    &__copy {
      justify-content: flex-end;
      margin-left: auto;
      max-width: 39%;
    }
  }
}

.b-propertyCardCarousel ~ .b-propertyCardCarousel {
  padding-top: 0;
  margin-top: 0;
}

// AFM Styling in case we need it
// & > .c-flow {
//   --flow-space: #{get-space('200')};
  
//   @media only screen and (min-width: 768px) {
//     --flow-space: #{get-space('400')};
//   }

//   @media only screen and (min-width: 992px) {
//     --flow-space: #{get-space('600')};
//   }
// }

// &__carouselWrapper {
//   margin-inline: -1.5rem;
// }

// .swiper {
//   width: 100%;

//   .swiper-slide {
//     justify-content: center;

//     & > div {
//       width: 100%;

//       & > .basicCard {
//         margin-inline: auto;
//       }
//     }
//   }

//   .basicCard {
//     height: 100%;
//     width: 100%;
//   }
// }
