.b-contactCallout {
  --cluster-vertical-alignment: stretch;
  --box-padding: #{get-space('800')} #{get-space('400')};
  --box-light: var(--color-grayscale-100);
  --eyebrow-color: var(--color-grayscale-100);

  font-size: 16px;
  background-color: var(--color-core-brand);
  background-repeat: no-repeat;
  background-size: cover;

  /**
   * Child elements
   */
  /* #region child elements */
  &__dividerBar {
    display: none;
    opacity: 0.5;
  }

  &__ctaWrapper {
    flex-grow: 1;

    .c-cluster {
      flex-grow: 1;
    }
  }

  .c-flow {
    --flow-space: #{get-space('200')};
  }
  /* #endregion */

  /**
   * Copy Components
   */
  /*#region copy components */
  .headline {
    font-size: get-text-size('5');
    margin-bottom: get-space('400');
  }
  .subheadline {
    font-size: get-text-size('2');
  }
  .eyebrow, .headline, .subheadline, .copy {
    color: var(--color-grayscale-100);
  }
  .eyebrow, .subheadline, .copy {
    opacity: .8;
  }
  /*#endregion */

  /**
   * Actions container
   */
  /* #region actions */
  & .b-actions {
    flex-shrink: 0;

    a {
      color: get-color('grayscale-primary-100');
      letter-spacing: 1px;
      text-decoration: none;
    }
  }
  /* #endregion*/

  /**
   * Media Queries
   */
  /* #region media queries */
  @include media-query("md") {
    // Reset namespace
    .b-contactCallout {
      &__copy {
        flex-basis: 30rem;
      }

      &__ctaWrapper {
        padding-bottom: get-space('400');
      }

      &__dividerBar {
        display: block;
        flex-grow: 1;
        height: 1px;
        width: auto;
      }
    }
  }
  /* #endregion */
}