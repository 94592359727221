/**
 * SWIPER
 * @see https://swiperjs.com/swiper-api
 * - Default sting for swiper carousel component

 * CUSTOM PROPERTIES AND CONFIGURATION
 * --swiper-overflow (hidden): Can be set to visible with --overflowOpen exception
 * to show slides that go beyond the elements width
 */
@import '../../../node_modules/swiper/swiper.scss';
@import '../../../node_modules/swiper/modules/navigation.scss';
@import '../../../node_modules/swiper/modules/pagination.scss';

.swiper {
  overflow: var(--swiper-overflow, hidden) !important;

  &-controls {
    display: flex;
    position: absolute;
    // top: -4rem;
    // right: 0;
    justify-content: flex-end;

    & > button {
      height: auto;
      left: 0;
      margin-top: 0;
      position: relative;
      top: 0;
      width: auto;
    }
  }

  &-slide {
    max-width: 100%; // Need to override global property
  }

  &--overflow {
    --swiper-overflow: visible;
  }

  &--overflowOpen {
    --swiper-overflow: visible;

    .swiper-slide {
      &:not(.swiper-slide-visible) {
        transition: opacity 0.3s ease-in;
        cursor: not-allowed;
        opacity: 0.3;

        & * {
          pointer-events: none;
        }
      }
    }
  }

  &-pagination {
    --swiper-pagination-bullet-size: 0.625rem;
    --swiper-pagination-bullet-inactive-color: #{get-color('core-primary-100')};
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-color: #{get-color('core-primary-500')};

    display: block;
  }
}

.swiper-controls {
  --icon-size: 0.65em;

  button {
    --button-size: calc(var(--icon-size) + 2em);
    align-items: center;
    border-radius: var(--swiper-button-border-radius, 50%);
    background-color: var(--theme-primary);
    display: flex;
    height: 30px;
    justify-content: center;
    transition:
      background-color 0.3s ease-in-out,
      border-color 0.3s ease-in-out;
    width: 30px;

    .b-icon {
      color: get-color('neutrals-tertiary-100');
      transition: color 0.3s ease-in-out;
      padding: 0.6em;
    }

    &::after {
      display: none;
    }

    &:hover {
      background-color: get-color('core-primary-500');
    }

    @include media-query('md') {
      --icon-size: 0.75em;
      height: var(--button-size);
      width: var(--button-size);
    }
  }

  &--box {
    button {
      --swiper-button-border-radius: var(
        --global-radius-base,
        $global-radius-base
      );
    }
  }
}
