/** REPEL
 * @see EL access: https://api.every-layout.dev/view?email=matt@inlikealion.com
 * A little layout that pushes items away from each other when
 * there is space in the viewport and stacks vertically
 * on smaller viewports

 * CUSTOM PROPERTIES AND CONFIGURATION
 * --gutter ($global-gutter): This defines the space
 * between each item.

 * --repel-vertical-alignment (center): How items should align
 * vertically. Can be set to any acceptable flexbox alignment value.
 */

.c-repel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: var(--repel-vertical-alignment, center);
  gap: var(--repel-gutter, $global-gutter);

  // For use with repels that may only contain one item
  // This exception will keep that singule item defaulted to justify end
  // Keeping the design more consistent
  &--single-justify-end {
    *:only-child {
      margin-inline-start: auto;
    }
  }
}
