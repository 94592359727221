/// GLOBAL VARIABLES
/// Global variables that should be accessible in all authored CSS

$global-gutter: map-get($gorko-space-scale, '500');
$global-gutter-narrow: 1.25rem;
$global-stroke: 1px solid get-utility-value('color', 'stroke');

/// Elements that feature in prose contexts need to have a consistent max width
$global-prose-element-max-width: 50rem;
//
$global-measure: 60ch;
// NOTE: there are more design token-led global variables in _tokens.scss

// Property Status Colors
$activeColor: get-color('state-good');
$pendingColor: get-color('state-info');
$soldColor: get-color('state-bad');
