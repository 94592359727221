.b-newsListingCallout {
  $self: &;

  --n: 3;
  --d: 2;

  .b-newsItem {
    .c-frame {
      max-width: 6.5rem;
      width: 100%;
    }
  }

  // Date bar animation
  .b-newsItem,
  &__media {
    .b-pseudoHr {
      transition: max-width 0.3s ease-in-out;
    }

    &:hover {
      .b-pseudoHr {
        max-width: 4rem;
      }
    }
  }

  // Responsive Styling
  &__desktopHeader {
    display: none;
  }

  &__mobileHeader {
    display: block;
  }

  &__mobileAction {
    display: flex;
  }

  &__category {
    color: var(--theme-primary);
  }

  @include media-query('md') {
    --n: unset;
    --d: unset;

    &__desktopHeader {
      display: flex;
    }

    &__mobileHeader {
      display: none;
    }

    &__mobileAction {
      display: none;
    }
  }
}
