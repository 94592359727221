/// VISUALLY HIDDEN UTILITY
/// Info: https://web.dev/design-system/css-utilities/#visually-hidden

.u-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
