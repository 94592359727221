/// GLOBAL CSS
/// Core CSS that is applicable to every page
/// https://cube.fyi/css.html

:root {
  --theme-primary: #{get-color('core-primary-500')};
}

[data-handle='huntingLeaseNetwork'] {
  --theme-primary: #{get-color('core-tertiary-500')};
  --eyebrow-color: var(--theme-primary);
}

body {
  @include apply-utility('font', 'base');
  @include apply-utility('leading', 'regular');

  color: get-color('neutrals-primary-500');
  font-size: get-text-size('1');
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background-color: get-color('neutrals-tertiary-100');
  color: get-color('neutrals-primary-500');

  &.b-body--locked {
    overflow-y: hidden;
  }

  // @include apply-utility('bg', 'core-bg');
  // @include apply-utility('color', 'core-text');
  @include apply-utility('font', 'base');
  @include apply-utility('leading', 'regular');
}

main {
  flex: auto;
  /**
  * [@seandepottey]: Don't remeber why I added this, think it might have been 
  * related to property card carousel. It's causing issues on the property listing 
  * pages though where when no results are found and a menu is opened it creates a 
  * vertical scroll bar so I'm removing. Might need to add something conditionally 
  * so it's only added to certain pages.
  * Quick testing of property card carousel makes it look like nothing is breaking.
  */
  // overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include apply-utility('font', 'brand');
  @include apply-utility('leading', 'flat');
  @include apply-utility('weight', 'regular');
}

h1 {
  font-size: get-text-size('5');
}

h2 {
  font-size: get-text-size('4');
}

h3 {
  font-size: get-text-size('3');
}

h4,
h5,
h6 {
  font-size: get-text-size('2');
}

strong {
  font-weight: bold;
}

/// General typesetting

p {
  max-width: 70ch;
}

img {
  max-width: 100%;
}

a {
  color: get-color('neutrals-primary-500');
  text-decoration: none;
  transition: color $global-transition-base;

  &:not(.b-anchorComplex) {
    &:hover,
    &:focus {
      color: var(--theme-primary);
    }
  }
}

li > ul {
  margin-left: 20px;
}

.display-none {
  display: none;
}
