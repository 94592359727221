.b-simpleCardGrid {
	&__content {
		margin-bottom: get-space('800');
	}

	&__heading {
		margin-bottom: get-space('500');
	} 

	.b-simpleCard {
		margin-bottom: get-space("600");
	}
}