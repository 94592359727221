#a2apage_full {
  background: transparent;
  border: none;
  border-radius: 0;
  color: get-color('grayscale-primary-100');
  height: 70%;
  left: 50%;
  max-width: 60rem;
  margin: 0;
  transform: translateX(-50%);
  width: 100vw;

  & * {
    border: none;
  }

  // Search Bar
  #a2apage_find_container {
    border-bottom: solid 2px get-color('grayscale-primary-100');
    border-radius: 0;
    margin-block-end: get-space('500');
    margin-inline: 1rem;

    #a2apage_find {
      color: get-color('grayscale-primary-100');
    }

    span.a2a_s_find svg {
      background-color: transparent;
    }
  }

  #a2apage_full_services {
    display: grid;
    grid-template-columns: repeat(
      var(--auto-grid-placement, auto-fill),
      minmax(var(--auto-grid-min-item-size, 16rem), 1fr)
    );
    gap: var(--gutter, $global-gutter);
    overflow-x: hidden;

    &::-webkit-scrollbar {
      background-color: transparent;
      &-track {
        // background: #274359;
        background: rgba(39, 67, 89, .8);
      }

      &-thumb {
        background: #182B39;
        &:hover {
          background: #1b3040;
        }
      }
    }
  }

  .a2a {
    &_full {
      &_header {
        &::before {
          content: "Share";
          display: block;
          font-size: 3rem;
          padding: 2rem 2rem 3rem 2rem;
          position: relative;
        }
      }

      &_footer {
        display: none;
      }
    }

    &_i {
      color: rgb(0, 0, 0) !important;
      border-radius: .625rem;
      display: flex;
      height: 6rem;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      width: auto;
      white-space: normal;
      transform: scale(1);
      transition: transform .3s ease-in-out, background-color .5s ease-in-out;

      .a2a_svg {
        position: relative;
        margin-inline: auto;
        margin-block-end: get-space('100');
        height: 3rem;
        width: 3rem;
      }

      &:hover, &:active {
        background-color: rgba(0,0,0,.2);
        transform: scale(1.1);
      }
    }
  }
}

#a2a_overlay {
  background-color: #fff;
}

// News detail specific
.c-withSidebar--wrapperCenter {
  .a2a_kit {
    a, button {
      --icon-size: 1.125rem;
      inline-size: auto;
      color: get-color('core-primary-500');

      @media only screen and (min-width: '1403px') {
        inline-size: 100%;
      }
    }
  }
}
