/* Legal Text Styling - used on property listing for legal text
 * Helps keep all legal text the same
 */
 .b-legal {
  font-size: get-text-size('0');
  // font-style: italic;
  max-width: none;
  
  p {
    max-width: none;
  }
}
