/* Property Fader - An Image fader wrapped with image count pill on hover

Sean:
A more generic name might be benficial in the for future applications.
Code was started in basic card, has not been implemented back into that component yet.
It was then refined and pulled out of property listing to be it's own component.
This is because it now appears also in the large scale land investments listing
*/
@mixin propertyFaderControlsActive {
  .b-propertyFader {
    &__faderControls {
      transform: scale(1);
      opacity: 1;
    }
  }
}

@mixin propertyFaderHover {
  @include propertyFaderControlsActive;

  .b-imageFader {
    @include imageFader();
  }
}

.b-propertyFader {
  $self: &;
  --fader-delay: 2s;

  .c-frame {
    border-radius: $global-radius-base;
    height: 100%;
    width: 100%;

    .b-imageFader {
      height: 100%;
      width: 100%;
    }
  }

  .b-icon {
    color: get-color('grayscale-primary-100');
  }

  &__faderControls {
    opacity: 0;
    transform: scale(1.25);
    transition:
      transform 0.3s ease-in-out,
      opacity 0.3s ease-in-out;

    .c-box {
      --box-padding: #{get-space('200')};
    }

    .b-favoritePropertyToggle {
      --icon-size: 1.75em;
      fill: get-color('neutrals-tertiary-500');

      &:focus,
      &:hover,
      &--active {
        fill: var(--theme-primary);
      }
    }

    .b-pill {
      margin-block-start: auto;
    }
  }

  &:focus,
  &:focus-visible,
  &:hover {
    @include propertyFaderHover;
  }
}

// This utility class could replace other uses of the mixin that went into
// specific components
.u-propertyFaderHover {
  &:hover {
    @include propertyFaderHover;
  }
}
