.b-footer {
  a {
    text-decoration: none;

    &.active {
      @include apply-utility('weight', 'bold');
      color: var(--theme-primary) !important;
    }
  }

  &__subnav a {
    color: var(--theme-primary);
  }

  &__multiSiteNav {
    border-bottom: 1px solid #{get-color('neutrals-tertiary-500')};
    border-top: 1px solid #{get-color('neutrals-tertiary-500')};
    letter-spacing: 0.0625em;

    a {
      font-size: 0.75rem;

      &:hover {
        color: var(--theme-primary);
      }
    }
  }

  &__socialMediaNav {
    .b-icon {
      font-size: 2em;
      color: var(--theme-primary) !important;
    }
  }

  &__legalAndSocial {
    --flow-space: #{get-space('300')};
    font-size: 0.875rem;

    &:hover {
      color: var(--theme-primary);
    }
  }

  @include media-query('md') {
    .b-footer {
      &__socialMediaNav {
        margin-left: auto;
      }
    }
  }
}
