.b-switch {
  $knobSize: 1.5;
  --switch-track-length: 2.75rem;

  cursor: pointer;
  position: relative;

  &__track {
    background-color: get-color('neutrals-tertiary-400');
    border-radius: 9999px;
    height: 0.875rem;
    overflow: hidden;
    pointer-events: none;
    position: relative;
    width: var(--switch-track-length);
  }

  &__activeTrack {
    background-color: var(--theme-primary);
    border-radius: 9999px;
    height: 100%;
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    transform: translateX(-100%);
    transition: transform 0.25s ease-in-out;
  }

  &__knob {
    background-color: get-color('neutrals-tertiary-300');
    border-radius: 50%;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    height: #{$knobSize}rem;
    position: absolute;
    width: #{$knobSize}rem;
    transform: translateX(0);
    transition: transform 0.25s ease-in-out;
  }

  &--on {
    .b-switch {
      &__activeTrack {
        transform: translateX(0);
      }

      &__knob {
        transform: translateX(
          calc(var(--switch-track-length) - #{$knobSize}rem)
        );
      }
    }
  }
}
