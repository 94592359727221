/* Sapcing Utility Mixin
 * 
 * A mixin for creating consistent spacing utils
 * Used with composition classes such as flow and switcher to target their 
 * css control variables
 */

@mixin spacingUtility($variableName: '--varName') {
  // #{$className} {
  // Max should eventually be tied to length of groko spacing utils directly
  @for $i from 1 through 9 {
    &--#{$i}00 {
      #{$variableName}: #{get-space('#{$i}00')};
    }
  }
}
