/* Zoom - Animation Utility
 * A util animation class with a hoverTarget & hoverEffect pair
 * 
 */
// Utility for zoom (transform scale) on hover
// Default In

@mixin zoomIn() {
  transform: scale(1.2);
}

@mixin zoomOut() {
  transform: scale(0.9);
}

@mixin zoomTarget() {
  transform: scale(1);
  transition: transform .3s ease-in-out;

  &:hover {
    @include zoomIn;
  }

  &--out {
    &:hover {
      @include zoomOut;
    }
  }
}

.u-hoverEffect-zoom {
  @include zoomTarget;
}

.u-hoverTarget-zoom {
  &:hover {
    .u-hoverEffect-zoom {
      @include zoomIn;
    }
  }
}