/* Contact Card 
  - Used on office pages to house phone number and address
  - Typically displayed within the sidebar or a c-withSidebar composition
  - Can replace b-agentProfileCard within layouts/agentProfile
*/
.b-contactCard {
  --icon-spacing: .5em;
  --eyebrow-color: #{get-color('core-primary-500')};
  --box-dark: #{get-color('neutrals-tertiary-400')};

  // background-color: get-color('neutrals-tertiary-400');

  .c-flow {
    align-items: center;
  }

  .subheadline {
    font-size: get-text-size('3');
  }

  .eyebrow {
    color: get-color('core-primary-500');
    font-size: .875em;
  }

  &--tightText {
    p:not(.eyebrow) {
      max-width: 60%;
    }
  }
}