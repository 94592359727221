.leaflet {
  $self: &;

  &-touch {
    #{$self} {
      &-bar {
        border: none;

        a {
          background-color: get-color('neutrals-tertiary-100');
          border-radius: $global-radius-base;

          &:first-child {
            margin-bottom: .25em;
          }

          span {
            align-items: center;
            display: flex;
            color: get-color('neutrals-primary-500');
            justify-content: center;
          }
        }
      }
    }
  }

  &-control {
    &-zoom {
      border-radius: $global-radius-base;
    }
  }
}