.b-proseCardGrid {
	.b-proseCardGrid__cards {
		flex-direction: column;

		@include media-query('lg') {
			flex-direction: row;
		}
	}

	&--horizontal {
		flex-wrap: wrap;
		row-gap: 30px;
	}

	&--vertical {
		column-gap: 30px;
	}
}