@charset "UTF-8";
/**
 * This front-end architecture is:
 * A. Built using CUBE CSS methodology.
 * B. Heavily inspired by https://web.dev/design-system/
 * C. With our own additional opinionated guidelines.
 *
 * See: ./README.md and https://cube.fyi/
 * Layout compositions: https://every-layout.dev
 *
 * Notes:
 * - When it comes to @includes mixins, they should always be first within a style block,
 *   followed by actual CSS properties (alphabetically, within reason)
 */

/**
 * CONTENTS
 *
 * SETTINGS
 * BASE
 * COMPOSITIONS
 * UTILITIES
 * BLOCKS
 * PAGES
 *
 */

/// SETTINGS ///
@import 'config';

// Disable util class generator because we'll render them at the end
$generate-utility-classes: false;

// Next: pull in gorko for design token and custom property generation
@import "node_modules/gorko/gorko.scss";

@import 'reset';
@import 'fonts';

/// GLOBAL VARIABLES
@import 'vars';
// NOTE: there are more design token-led global variables in _tokens.scss

/// FUNCTIONS
@import 'functions/get-space';
@import 'functions/get-text-size';

/// MIXINS
@import 'mixins/absolute';
@import 'mixins/buttonBaseStyles';
@import 'mixins/clearfix';
@import 'mixins/custom-bg';
@import 'mixins/noSpaceOnMobile';
@import 'mixins/imageFader';
@import 'mixins/overlay';
@import 'mixins/pseudo';
@import 'mixins/propertyFader';
@import 'mixins/roundedBorder';
@import 'mixins/spacingUtility';
@import 'mixins/verticallyAlignLabel';




/// BASE CSS
/// Core CSS that is applicable to every page
@import 'base/animations';
@import 'base/global';
@import 'base/elements';
@import 'base/thirdparty';





/// Layout COMPOSITIONS ///
@import 'compositions/autoGrid';
@import 'compositions/box';
@import 'compositions/breakout';
@import 'compositions/center';
@import 'compositions/cluster';
@import 'compositions/cover';
@import 'compositions/flow';
@import 'compositions/frame';
@import 'compositions/imposter';
@import 'compositions/layer';
@import 'compositions/reel';
@import 'compositions/repel';
@import 'compositions/sidebar';
@import 'compositions/switcher';
@import 'compositions/withIcon';
@import 'compositions/wrapper';





/// UTILITY CLASSES ///
@import 'utilities/a11yUtilities';
@import 'utilities/background';
@import 'utilities/darken';
@import 'utilities/clearfix';
@import 'utilities/textLineClamp';
@import 'utilities/custom-bg';
@import 'utilities/grunge';
@import 'utilities/itemReveal';
@import 'utilities/layout';
@import 'utilities/visuallyHidden';
@import 'utilities/shift';
@import 'utilities/simpleReveal';
@import 'utilities/zoom';




/// Component BLOCKS ///
@import 'blocks/addToAny';
@import 'blocks/agentCard';
@import 'blocks/agentListing';
@import 'blocks/articleCard';
@import 'blocks/basicCard';
@import 'blocks/button';
@import 'blocks/categoryDateBar';
@import 'blocks/contactCallout';
@import 'blocks/contactCard';
@import 'blocks/collapseButton';
@import 'blocks/copyBlock';
@import 'blocks/currentRefinements';
@import 'blocks/embedCode';
@import 'blocks/eyebrow';
@import 'blocks/faq';
@import 'blocks/footer';
@import 'blocks/forms';
@import 'blocks/graphCard';
@import 'blocks/header';
@import 'blocks/hero';
@import 'blocks/hoverBar';
@import 'blocks/icon';
@import 'blocks/imageGrid';
@import 'blocks/leaflet';
@import 'blocks/legal';
@import 'blocks/listingBase';
@import 'blocks/listingDropdown';
@import 'blocks/listingPagination';
@import 'blocks/listingRangeSlider';
@import 'blocks/listingSortBy';
@import 'blocks/listingTable';
@import 'blocks/locationCard';
@import 'blocks/mediaAndCopy';
@import 'blocks/modal';
@import 'blocks/mediaAndCopy';
@import 'blocks/newsGrid';
@import 'blocks/newsListingCallout';
@import 'blocks/pagination';
@import 'blocks/pill';
@import 'blocks/propertyCard';
@import 'blocks/propertyCardCarousel';
@import 'blocks/propertyListing';
@import 'blocks/profileMain';
@import 'blocks/profilePagination';
@import 'blocks/profileSidebar';
@import 'blocks/propertyDetailHero';
@import 'blocks/propertyDetailSidebar';
@import 'blocks/propertyListing';
@import 'blocks/propertyListingCallout';
@import 'blocks/propertyCardGrid';
@import 'blocks/propertySearchMap';
@import 'blocks/propertyStatus';
@import 'blocks/proseCard';
@import 'blocks/proseCardGrid';
@import 'blocks/pseudoHr';
@import 'blocks/relatedDocuments';
@import 'blocks/remainderIcon';
@import 'blocks/richText';
@import 'blocks/saleTypeCard';
@import 'blocks/searchBar';
@import 'blocks/skipMain';
@import 'blocks/simpleCard';
@import 'blocks/simpleCardCarousel';
@import 'blocks/simpleCardGrid';
@import 'blocks/statusIndicator';
@import 'blocks/stockListing';
@import 'blocks/swiper';
@import 'blocks/switch';
@import 'blocks/tabbedTable';
@import 'blocks/testimonialCarousel';
@import 'blocks/text-and-actions-callout';





/// PAGE Layouts ///
@import 'page-layouts/agentProfile';
@import 'page-layouts/sidebar.scss';





// LASTLY, Generate utilities after everything else
@include generate-utility-classes();
