/**
 * Styles for our Property Search Map and Leaflet instance
 */

$map-marker-bg-color: get-color('neutrals-primary-500');
$map-marker-bg-color-hover: get-color('neutrals-primary-100');
$map-marker-txt-color: get-color('grayscale-tertiary-400');

// Sean: This should be moved to animations, it's generic enough
@keyframes spin {
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.propertySearchMap {
  $self: &;
  --box-padding: #{get-space('600')};
  --flow-space: #{get-space('600')};

  margin-bottom: get-space('800');
  margin-top: get-space('800');

  .b-listingDropdown__content {
    display: none !important;
  }

  .propertySearchMap__searchBoxWrapper {
    --box-padding: 0.5rem 0.75rem;

    input {
      padding-inline-start: 2.5rem;
    }
  }

  .esri-truncated-attribution {
    cursor: pointer;

    &:hover {
      white-space: nowrap;
    }

    &.u-whitespace-normal {
      white-space: normal;
    }
  }

  &.loading {
    // reset namespace
    #{$self} {
      &__content {
        &::after {
          display: block;
        }

        & > * {
          height: 0;
          overflow: hidden;
          padding-block: 0;
          padding-inline: 0;
        }
      }
    }
  }

  &__content {
    background-color: get-color('neutrals-tertiary-100');
    border-radius: $global-radius-base;
    transition: transform $global-transition-base;
    transform-origin: 0;
    z-index: 999; // Place on top of leaflet things

    &::after {
      @include pseudo;
      @include absolute($top: 50%, $left: 50%);
      animation: spin 1s linear infinite;
      border: 5px solid var(--theme-primary);
      border-top: 5px solid transparent;
      border-radius: $global-radius-full;
      display: none;
      height: 3.125em;
      transform: translate(-50%, -50%);
      transform-origin: 50% 50%;
      width: 3.125em;
    }

    // TODO: use updated query(ies) from master
    // @include media-query("xs") {
    //   @include absolute($top: 15%, $left: 10%);
    // }

    // Style any links that might live in a <p> or come from the CMS
    & p > a {
      color: $map-marker-txt-color;
      text-decoration: none;
      transition: all $global-transition-base;

      &:focus,
      &:hover {
        color: lighten($map-marker-txt-color, 15%);
        text-decoration: underline;
      }
    }

    &__header {
      &__resultText {
        margin-right: get-space('200'); // Don't let things collide on mobile
      }
    }
  }

  .b-searchDropdown__Button {
    &__content {
      input {
        color: #{get-color('neutrals-primary-300')};
        font-weight: 700;
        width: 130px;
      }

      span {
        display: none;
        font-size: get-text-size('base');
        margin-right: 5px;
      }

      .b-icon {
        display: none;
      }
    }
  }

  &__mapContainer {
    border-radius: $global-radius-base;
    display: block;
    height: 450px; // TODO: aspect ratio?
    width: 100%;

    @media only screen and (min-width: 768px) {
      height: 700px;
    }
  }

  @media only screen and (min-width: 768px) {
    margin-bottom: 0px;

    > .c-wrapper {
      width: 100%;
    }

    &__wrapper {
      position: static;
      background-color: #{get-color('neutrals-tertiary-400')};
      width: 100%;
      z-index: 9999;
    }

    &__content {
      --flow-space: 0px;
      align-items: center;
      background: transparent;
      box-shadow: none;
      flex-direction: row;
      justify-content: space-between;
      max-width: none;
      margin-inline: auto;
      position: static;
      width: var(--wrapper-width-max, 75rem);
      width: var(--wrapper-width, clamp(16rem, 95vw, 75rem));
      padding-inline: var(--wrapper-padding, 0);
      padding-block: #{get-space('600')};

      &__header {
        flex-direction: row;
      }

      &__copy {
        display: none;
      }

      &__actions {
        justify-content: flex-end;
      }

      .b-searchDropdown {
        width: 18rem;
      }

      .b-listingDropdown__select.c-box {
        --box-dark: #{get-color('neutrals-tertiary-100')};
      }
    }
  }

  @include media-query('lg') {
    --box-padding: #{get-space('700')};

    &__wrapper {
      bottom: -120px;
    }

    &__content {
      justify-content: space-between;

      &__actions {
        width: 50%;
      }

      .b-listingDropdown__select {
        padding-inline: 10px;

        .b-listingDropdown__caret {
          display: none !important;
        }
      }

      .b-searchDropdown__Button {
        flex-wrap: nowrap;
        gap: 0px;

        &__content {
          align-items: center;
          color: #{get-color('neutrals-primary-300')};
          display: flex;
          flex: 1 0 auto;

          span {
            display: block;
          }

          input {
            width: 100%;
          }

          .b-icon {
            display: block;
            margin-right: 24px;
          }
        }
      }
    }
  }
}

/**
 * Custom marker styles for all things leaflet
 */
.custom-marker {
  @include apply-utility('weight', 'demi');
  // border: 1px solid transparentize(get-color("grayscale-primary-100"), 0.8);
  color: $map-marker-txt-color;
  // Importants used here to override inline styles
  height: auto !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
  white-space: nowrap;
  width: auto !important;

  & a {
    align-items: center;
    background-color: $map-marker-bg-color;
    border-radius: $global-radius-base;
    color: $map-marker-txt-color;
    display: flex;
    height: 100%;
    padding: 8px 10px;
    text-decoration: none;
    transition: all $global-transition-base;
    width: 100%;

    transform: translate(-50%, -54px);

    &:hover,
    &:focus {
      background-color: $map-marker-bg-color-hover;
      padding-right: 15px;

      &::after {
        border-top: 10px solid $map-marker-bg-color-hover;
      }

      & i.arrow {
        opacity: 1;
        width: auto;
      }
    }

    // Pseudo triangle
    &::after {
      @include pseudo;
      @include absolute($top: 100%, $left: 50%);
      border: 10px solid transparent;
      border-top: 10px solid $map-marker-bg-color;
      height: 0;
      top: calc(100% - 1px);
      transform: translateX(-50%);
      transition: all $global-transition-base;
      width: 0;
    }
  }

  & i.arrow {
    color: $map-marker-txt-color;
    opacity: 0;
    transition: opacity $global-transition-base;
    transform: translateX(5px);
    width: 0;

    & svg {
      height: 0.75em;
      width: 0.75em;
    }
  }

  // Circle representing PropertyStatus
  & i:first-of-type {
    // TODO: gorko color?
    background-color: #d3bc40; // Default for non-active and non-sold
    border-radius: $global-radius-full;
    display: inline-block;
    height: 10px;
    margin-right: 5px;
    width: 10px;

    &.active {
      // TODO: gorko color?
      background-color: #439750;
    }

    &.sold {
      // TODO: gorko color?
      background-color: #c76868;
    }
  }

  .b-agentMapIndicator {
    --icon-size: 1rem;
    --icon-spacing: 0.5em;
    transform: translate(-44%, -100%);
  }
}

// Overwrite default cluster styles
.marker-cluster {
  background-color: $map-marker-bg-color;
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.5);
  color: $map-marker-txt-color;

  &:focus,
  &:hover {
    & > div {
      background-color: $map-marker-bg-color-hover;
      transform: scale(1);
    }
  }

  & > div {
    background-color: $map-marker-bg-color;
    transform: scale(1.25);
    transition: transform $global-transition-base;
  }
}

// remove margin when map is under the hero
.hero + .propertySearchMap {
  margin-top: 0px;
}
