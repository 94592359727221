/* Text Line Clamp - u-text-line-clamp--#{x}
 * A text utility class for capping text based on
 * a number of lines.
 *
 * WARNING: Be wary of using on headlines, titles, etc.
 * (prioritize the user's access to important content)
 * Best reserved for paragraph copy such as long article
 * teaser copy.
 */
@mixin textLineClamp($i) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: #{$i};
  overflow: hidden;
  text-overflow: ellipsis;
}

@for $i from 1 through 5 {
  .u-text-line-clamp--#{$i} {
    @include textLineClamp($i);
  }
}
