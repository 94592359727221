/**
 * .b-remainderIcon Block
 *
 * Adds layout and interaction for icon spaced out
 * from end of a link, with an animated line ornament
 * which takes up the remainder space

  <a class="b-with-remainder">
    Link Text
    <div class="b-remainderIcon">
      <div class="b-remainderIcon__ornament"></div>
      <svg class="b-icon"><use href="/svg/sprite.svg#arrow-right"></use></svg>
    </div>
  </a>

 */

.b-with-remainderIcon {
  display: flex; // .u-box-flex
  flex-direction: row; // .u-flex-dir-row
  align-items: center; // .u-flex-align-center
  justify-content: space-between; // .u-flex-justify-between
  position: relative;

  &:hover,
  &:focus {
    .b-icon {
      transform: translateX(1em);
    }
  }
}

.b-remainderIcon {
  @include absolute($right: -25px, $top: 50%);
  align-items: center;
  display: flex; // .u-box-flex
  flex-grow: 1; // Let the bar span the remainder
  transform: translateY(-50%);
  transition: opacity ease 0.5s;
  width: auto;

  .b-icon {
    transition: transform ease 0.5s;
  }
}

// In case we want a line separating the arrow from text
.b-remainderIcon__ornament {
  background-color: currentColor;
  height: 2px;
  margin-left: get-space("200");
  margin-right: get-space("100");
  transform-origin: 0;
  transform: scaleX(0.5);
  transition: transform ease 0.5s;
  width: auto;
  opacity: 0.05;
  flex-grow: 1; // Let the bar span the remainder
}
