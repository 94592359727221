@mixin shiftXPositive() {
  transform: translateX(1rem);
}

@mixin shiftTarget() {
  transform: translate(0);
  transition: transform .3s ease-in-out;

  &:focus,
  &:hover {
    @include shiftXPositive;
  }
}

.u-hoverEffect-shift {
  @include shiftTarget();
}

@include media-query('md') {
  .md\:u-hoverEffect-shift {
    @include shiftTarget();
  }
}

.u-hoverTarget-shift {
  &:focus,
  &:hover {
    .u-hoverEffect-shift {
      @include shiftXPositive();
    }
  }
}

@include media-query('md') {
  .md\:u-hoverTarget-shift {
    &:focus,
    &:hover {
      .u-hoverEffect-shift, .md\:u-hoverEffect-shift {
        @include shiftXPositive();
      }
    } 
  }
}