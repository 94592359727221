a.skip-main {
  background-color: get-color('core-primary-500');
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color: #fff;
  font-size: 1em;
  left: 50%;
  overflow: hidden;
  padding: 5px 20px;
  position: absolute;
  text-align: center;
  top: 0;
  transform: translate(-50%, -100%);
  transition: transform ease-in-out 0.3s;
  z-index: -999;
}

a.skip-main:focus, a.skip-main:active {
  transform: translate(-50%, 0);
  z-index: 9999999;
}