.b-agentListingBase {
  &__hero {
    & .headline {
      max-width: 35rem;
    }
  }
}

.b-agentListing {
  //SCSS Vars
  $self: &;
  // CSS Vars
  --cluster-horizontal-alignment: center;
  --gutter: 1rem;
  --icon-spacing: .5em;
  --profile-image-size: 12.5rem;
  --repel-vertical-alignment: flex-start;
  /* #region - Main Styles */
  line-height: 1;

  &__image {
    max-width: var(--profile-image-size);
    flex-grow: 1;

    img {
      transform: scale(1);
      transition: transform .3s ease-in-out;
    }
  }

  // This type of em measurement could be mixed in into components
  // Like the copy block as percentage variables possibly using calc
  p {
    font-size: .889em;
  }

  // Still wondering if sidebar or switcher would be better for the listings
  &__content {
    flex-basis: 100%;

    transform: translateX(0);
    transition: transform .3s ease-in-out;

    @include media-query('xs') {
      flex-basis: calc(100% - calc(var(--profile-image-size) + var(--gutter)));
      flex-grow: 1;

      .c-repel {
        justify-content: flex-start;
      }
    }
  }

  &__phones {
    // text-align: right;
    align-items: flex-end;
  }

  &__viewProfile {
    background-color: get-color('grayscale-primary-300');
  }

  &__name {
    text-transform: capitalize;
  }
  /* #endregion - Main Styles */

  // Hover Styles
  &:focus,
  &:hover {
    #{$self} {
      &__image {
        img {
          transform: scale(1.1);
        }
      }

      &__content {
        transform: translateX(1rem);
      }
    }
  }

  @include media-query('sm') {
    --gutter: 2rem;
  }

  @include media-query('md') {
    --gutter: 3rem;
  }
}
