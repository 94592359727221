/* Agent listing styling - used on agent listings page */
.b-agentCard {
  --box-dark: #{get-color('neutrals-tertiary-400')};
  --box-padding: #{get-space('400')};
  border-radius: $global-radius-base + 2;

  .c-frame {
    border-radius: $global-radius-base;
    max-width: 15rem;
    width: 100%;
  }

  .c-flow {
    --flow-space: #{get-space('200')};
  }

  .headline {
    font-size: get-text-size('3');
  }

  .eyebrow {
    color: #{get-color('core-primary-500')};
    line-height: 1.1;
    padding-block: 0;
  }

  .b-agentCard__buttons {
    --gutter: .5em;
  }

  .b-button {
    font-size: .75em;
    // color: get-color('grayscale-primary-100');
  }

  &__phones {
    --repel-gutter: .75em;
    font-size: 1rem;
  }
}
