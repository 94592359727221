.b-listingPagination {
    display: flex;
    gap: get-space('base'); 
    justify-content: center;

    &__main,
    &__arrows {
        display: flex;
        gap: get-space('300'); 
        list-style: none;

        .ais-Pagination {
            &-link,
            &-text
            &-list .ais-Pagination-item a {
                align-items: center;
                background-color: get-color('neutrals-tertiary-400');
                color: get-color('neutrals-primary-100');
                display: flex;
                font-size: get-text-size('1');
                font-weight: 700;
                height: 40px;
                justify-content: center;
                letter-spacing: -0.056em;
                width: 40px;
            }

            &-list {
                .ais-Pagination-item--nextPage a.ais-Pagination-link {
                    height: 40px;
                }
            }

            &-item:not(.ais-Pagination-item--selected) a:hover {
                background-color: get-color('neutrals-tertiary-500');
            }
    
            // &-link,
            // &-list .ais-Pagination-item a {
            //     background-color: get-color('neutrals-tertiary-400');
            //     font-size: get-text-size('1');
            //     font-weight: 700;
            //     letter-spacing: -0.056em;
            //     width: 40px;
            // }
    
            &-text {
                font-size: get-text-size('base');
                letter-spacing: 0.01em;
            }

            &-item.ais-Pagination-item--nextPage,
            &-item.ais-Pagination-item--prevPage {
                a:hover {
                    background-color: get-color('core-primary-500');
                    color: #fff;
                }
            }
    
            &-item.ais-Pagination-item--disabled {
                .ais-Pagination-link {
                    background-color: get-color('neutrals-tertiary-500');
                    color:#fff;
                }
            }
    
            &-item.ais-Pagination-item--selected {
                .ais-Pagination-link {
                    @include grungeBg(0);
                    background-color: get-color('core-primary-500');
                    color: #fff;
                }   
            }
        }
    }

    &__divider {
        align-self: center;
        background-color: get-color('core-primary-100');
        display: none;
        flex: 1 0 auto;
        height: 1px;
    }

    &__main {  
        li {
            &:first-child,
            &:last-child {
                display: none;
            }
        }

        & + .b-listingPagination__divider {
            display: block;
        }
    }

    @include media-query('lg') {
        padding-inline: 50px;
        position: relative;

        &__divider {
            display: block;
        }

        &__arrows {
            li {
                position: absolute;
                &:first-child {
                    left: 0px;
                }
                &:last-child {
                    right: 0px;
                }
            }
        }
    }
}