.b-copyBlock {
  h2 {
    --flow-space: #{get-space('600')};
  }

  ul, ol {
    margin-left: 1rem;
  }

  p {
    font-size: get-text-size('2');
  }
}