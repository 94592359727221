.b-profileSidebar {
  max-height: 280px;

  &__title {
    letter-spacing: 2px;
    font-size: get-text-size('00');
    line-height: 18px;
    color: var(--theme-primary);
  }

  &__item {
    &--active {
      background-color: var(--theme-primary);
      color: get-color('grayscale-tertiary-400');
      border-radius: 3px;

      > a {
        color: get-color('grayscale-tertiary-400');
      }
    }

    > a {
      display: flex;
      align-items: center;

      .icon-size {
        width: 20px;
        height: 20px;
      }

      > span {
        font-weight: 500;
        font-size: get-text-size('1');
        line-height: 18px;
      }
    }
  }
}
