.b-saleTypeCard {
  --repel-gutter: 0.5em;
  --icon-spacing: 0.75em;

  &__location {
    :last-child {
      max-width: 53%;
    }
  }

  &__location,
  &__listItem {
    .b-icon {
      color: var(--theme-primary);
      font-size: 1.5rem;
    }
  }
}
