/// BACKGROUND UTILITY
/// set custom background for eyebrows

.u-bg-customImage {
  @include custom-bg();
}

.u-bg-image {
  @include bg-img();
}
