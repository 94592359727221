/**
 * Position Absolute base styles
 */
@mixin absolute($top: auto, $right: auto, $bottom: auto, $left: auto) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin absoluteCenterX {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
