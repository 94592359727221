a,
i,
button {
  cursor: pointer;
}

sup {
  font-size: 0.5em;
  vertical-align: super;
}

hr {
  width: 100%;
  height: 1px;
  margin: 0;
  border: 0;
  background-color: map-get($gorko-colors, "offwhite");
}

[type=text],
[type=number],
[type=search],
[type=password],
[type=email],
[type=tel],
[type=radio],
[type=submit],
select,
textarea {
  padding: 0;
  border: 0;
  border-radius: 0;
  background: none;
  font-size: 16px;
  // -webkit-appearance: none;
  &:-ms-expand {
    display: none;
  }
}

[type=radio] {
  border-radius: 50%;
}

button {
  padding: 0;
  border: 0;
  border-radius: 0;
  background: none;
  &:disabled {
    pointer-events: none;
  }
}

img {
  max-width: 100%;
}