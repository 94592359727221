.b-statusIndicator {
    background-color: var(--indicator-color, #{get-color('state-good')});
    border-radius: 999px;
    height: get-space("400");
    width: get-space("400");

    &--active {
        --indicator-color: #{$activeColor};
    }

    &--pending,
    &--contract-pending,
    &--inactive,
    &--pending-approval,
    &--under-contract {
        --indicator-color: #{$pendingColor};
    }

    &--sold {
        --indicator-color: #{$soldColor};
    }
}
