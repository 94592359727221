.b-simpleCard {
  $self: &;

  // Add black behind image
  .c-frame {
    background-color: get-color('grayscale-primary-500');
  }

  &::after,
  &__icon {
    background-color: get-color('core-primary-500');
    background-blend-mode: screen;
    background-image: url('/dist/img/bg-texture-grunge.jpg');
    background-repeat: no-repeat;
  }

  &__content {
    background-color: get-color('grayscale-primary-500');
    overflow: hidden;
    position: relative;
    width: 100%;
    transition: transform 0.3s ease-in-out;
  }

  &__image,
  &__icon,
  &__headline,
  &__media {
    transition: $global-transition-fade;
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__icon {
    align-items: center;
    background-color: var(--theme-primary);
    background-position: center right;
    display: flex;
    justify-content: center;
    opacity: 0;

    svg {
      color: get-color('grayscale-tertiary-400');
    }
  }

  &__headline {
    color: get-color('neutrals-primary-500');
    padding-left: get-space('500');
    padding-right: get-space('500');
  }

  &:focus,
  &:hover {
    #{$self} {
      &__icon {
        opacity: 1;
      }
    }
  }

  /* #region - Exceptions */
  &--square {
    // Grunge Bar
    &::after {
      @include absolute($top: 100%, $left: 0);
      @include pseudo;
      background-position: center -100px;
      height: 13px;
      transform: translateY(0);
      transition: $global-transition-fade;
      width: 100%;
    }

    #{self} {
      &__image {
        opacity: 0.8;
      }
    }

    &:focus,
    &:hover {
      &::after {
        transform: translateY(-100%);
      }

      #{$self} {
        &__image {
          transform: scale(1.3);
        }
      }
    }
  }

  &--rectangular {
    max-width: 270px;

    &:focus,
    &:hover {
      #{$self} {
        &__headline {
          color: get-color('core-primary-500');
        }

        &__media {
          transform: scale(0.9);
        }
      }
    }

    @include media-query('md') {
      max-width: none;
    }
  }

  &--inactive {
    pointer-events: none;

    .c-layer * {
      pointer-events: none;
    }
  }
  /* #endregion - Exceptions */
}
