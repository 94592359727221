/* Item Reveal Content - Utility
 * A collection of classes include a target class for the hover to trigger off of
 * A content wrapping class to control overflow
 * And a inner content wrapper that shifts an item out of view
 * Current limitation is the this only reveals from the bottom,
 * Either through exceptions or other means this could be altered to reveal
 * from any of trbl
 * 
 * Terms
 * Item: the item / content that is revealed
 * Reveal: the item's content height + it's padding and the top margin between it and above item
 *
 * CUSTOM PROPERTIES AND CONFIGURATION
 * --content-size (1rem): Height of content ex: --icon-size to reveal a CTA
 * --item-reveal-padding (get-space('400')): Padding of item to reveal
 * --item-reveal-margin (get-space('400')): Margin above revealed item
 *
 * Internal Variables
 * --item-block-padding: Represents block padding
 * --animation-height: Represents the item height and relevant spacing use to transform the reveal item to hiding it while accepting different sizes.
 */

@mixin itemRevealActiveState() {
  .u-hoverEffect-itemReveal {
    transform: translateY(0);
  }
}

@mixin itemRevealHoverActiveException() {
  &--active {
    @include itemRevealActiveState();
  }
}

// Target Class
.u-hoverTarget-itemReveal {
  /* 
   * CSS Variables
   */
  // Reveal items height is the block padding (padding * 2) + item height
  /*
   * This line fails the css parser (minification process) with a likely culprit 
   * being cssnano so in order to avoid that the variables with defaults need to
   * be "unnested" this is done with --parser-bypass-var's which are just 
   * there to set default values
   */
  // --item-block-padding: calc(2 * var(--item-reveal-padding, #{get-space('400')}));
  --parser-bypass-padding: var(--item-reveal-padding, #{get-space('400')});
  --item-block-padding: calc(2 * var(--parser-bypass-padding));
  --item-reveal-size: calc(var(--item-block-padding) + var(--content-size, 1rem));
  // Animation height is the item height + spacing (margin)
  --parser-bypass-margin: var(--item-reveal-margin, #{get-space('400')});
  --animation-height: calc(var(--item-reveal-size) + var(--parser-bypass-margin));

  // Wrapping element that controls the overflow
  &__contentWrapper {
    height: 100%;
    overflow: hidden;
  }

  /* 
   * Target Element for animation (revealed items wrapper)
   */
  // Default Hover state hidden
  .u-hoverEffect-itemReveal {
    transform: translateY(var(--animation-height));
    transition: transform .3s ease-in-out;
  }

  &:focus,
  &:hover {
    @include itemRevealActiveState;
  }
}

.max-md\:u-hoverTarget-itemReveal {
  @media only screen and (max-width: 879.99px) {
    @include itemRevealHoverActiveException;
  }
}

.max-lg\:u-hoverTarget-itemReveal {
  @media only screen and (max-width: 1279.99px) {
    @include itemRevealHoverActiveException;
  }
}