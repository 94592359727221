.b-tabbedTable {
  $self: &;
  --eyebrow-color: #{get-color('grayscale-primary-500')};
  --box-padding: #{get-space('400')} #{get-space('600')};

  .c-repel, .c-cluster {
    flex-wrap: nowrap;
  }

  &__tabs {
    --gutter: 0;
    --box-padding: #{get-space('400')} #{get-space('200')};
    --cluster-horizontal-alignment: center;
    flex-wrap: nowrap;
    
    @include media-query('xs') {
      --gutter: .25rem;
    }

    @include media-query('sm') {
      --gutter: .5rem;
    }
  }

  &__row {
    &__title {
      flex-basis: 25%;
    }
  }
}