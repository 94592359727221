.b-propertyStatus {
  align-items: center;
  color: get-color('neutrals-primary-100');
  display: flex;
  position: relative;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 10;

  &::before {
    content: '';
    width: 0.75rem;
    height: 0.75rem;
    background-color: #000;
    border-radius: 50%;
    margin-inline-end: 0.5rem;
  }

  &--Active, &--active {
    &::before {
      background-color: $activeColor;
    }
  }

  &--Sold, &--sold {
    &::before {
      background-color: $soldColor;
    }
  }

  &--UnderContract, &--Inactive, &--Pending, &--ContractPending, &--PendingApproval,
  &--underContract, &--inactive, &--pending, &--contractPending, &--pendingApproval {
    &::before {
      background-color: $pendingColor;
    }
  }
}