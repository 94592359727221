.b-formWrapper {
  max-width: 100%;

  .ff-form-errors {
    color: red;
    margin-bottom: 1em;
  }
}

.b-form {
  --flow-space: #{get-space('600')};

  .ff-errors {
    list-style-type: none;
    color: red;
    margin-top: 1em;
    font-size: 14px;
  }

  fieldset {
    --flow-space: #{get-space('200')};
    flex-grow: 1;
    flex-basis: 0;
    left: 0;
  }

  &__inputLabel {
    font-size: get-text-size('0');
    font-weight: 400;
    line-height: 1.285;
    color: get-color('neutrals-primary-500');
  }

  &__hintLabel {
    font-size: get-text-size('0');
    &.hint--negative {
      color: get-color('state-bad');
    }
  }

  &__inputIcon {
    display: flex;
    align-items: baseline;
    position: relative;

    .b-icon {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(-50%, 150%);
    }

    &--passwordIcon {
      .b-icon {
        transform: translate(-80%, 150%);
      }
      .b-icon.icon--passwordIcon {
        transform: translate(-60%, 150%);
      }
    }
  }

  &__inputTextArea {
    min-height: 13em;
    resize: vertical;
    font-size: get-text-size('0');
    line-height: 1.575;
    padding: 18px;
  }

  .b-rememberMe,
  .b-controls {
    align-self: center;
  }
}

.b-input {
  --box-padding: #{get-space('400')} #{get-space('400')};
  --box-light: #{get-color('neutrals-primary-500')};
  --box-dark: #{get-color('neutrals-tertiary-400')};
  border: 2px solid transparent;
  border-radius: $global-radius-base;
  display: block;
  font-size: get-text-size('1');
  line-height: 1.75;
  outline: none;
  font-size: 14px;
  line-height: 18px;

  // Hide buttons by default
  .c-layer {
    --gutter: 0.5rem;
    background-color: transparent;
    z-index: 2;

    button {
      display: flex;
      align-items: center;
    }

    .b-input__control {
      display: none;
    }

    .b-input__showPassword {
      display: block;
    }

    .b-input__hidePassword {
      display: none;
    }

    & > .b-icon {
      display: none;
    }
  }

  input {
    font-size: get-text-size('0');
    position: relative;
    width: 100%;
    z-index: 1;
    line-height: 1.285;
    padding: #{get-space('400')} ;

    &:focus-visible {
      outline: 2px solid get-color('grayscale-primary-500');
    }
  }

  select {
    font-size: get-text-size('0');
    line-height: 1;
  }

  &::placeholder {
     font-size: get-text-size('0');
    color: #6b6b6b;
    font-size: 14px;
    line-height: 18px;
  }
  &.input--positive {
    background-color: get-color('state-good-100');
    border: 2px solid get-color('state-good-300');
  }

  &.input--negative {
    background-color: get-color('state-warn-100');
    border: 2px solid get-color('state-warn-300');
  }

  &:active {
    background-color: #eeeeee;
    box-shadow: inset 0px 300px 0px rgba(0, 0, 0, 0.08);
    border: 2px solid transparent;
  }

  &:focus {
    border: 2px solid #000;
    border-radius: 0px;
  }
}

.b-select {
  --box-padding: #{get-space('200')} #{get-space('400')};
  --box-light: #{get-color('neutrals-primary-500')};
  --box-dark: #{get-color('core-secondary-100')};
  position: relative;

  select {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    width: 100%;
  }

  // Hide buttons by default
  .c-layer {
    background-color: transparent;
    z-index: 2;
  }
}

// Can replace class in listingDropdown as well
.b-dropdownCaret {
  transition: transform 0.25s ease-in-out;
}

.b-inputHint {
  position: absolute;
  left: 0;
  margin: 0;
  top: calc(100% + 0.25em);
}
