.b-locationCard {
  --box-padding: #{get-space('500')};
  --box-light: #{get-color('neutrals-primary-500')};
  --box-dark: #{get-color('neutrals-tertiary-400')};
  --flow-space: #{get-space('600')};
  --icon-spacing: 0.5rem;
  border-radius: $global-radius-base;
  max-width: 24rem;

  &.c-box {
    --box-padding: #{get-space('600')};
  }

  .headline {
    font-size: get-text-size('2');
    padding-bottom: get-space("200");
    padding-top: get-space("200");
  }

  .locationCard__map {
    --n: 4;
    --d: 3;
    border-radius: $global-radius-base;
  }

  &__contact-info {
    &.c-flow {
      --flow-space: #{get-space('200')};
    }
  }
}
