.b-articleCard {
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px var(--color-grayscale-300) solid;
  border-radius: $global-radius-base;
  overflow: hidden;
  transition: all $global-transition-base;

  .c-frame {
    img {
      transform: scale(1);
      transition: transform 0.3s ease-in-out;
    }
  }

  .headline {
    transition: color 0.3s ease-in-out;
  }

  &:focus,
  &:hover {
    border-color: var(--color-grayscale-400);

    .c-frame {
      img {
        transform: scale(1.2);
      }
    }

    .headline {
      color: var(--theme-primary);
    }

    .hoverBar {
      transform: translateX(0);
    }
  }
}

.articleCard__linkwrap {
  display: block;
}
