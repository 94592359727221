.ais-RangeSlider {
  --range-slider-handle-size: 1.25rem;
  display: flex;
  justify-content: center;

  .rheostat {
    $rangeSliderBorderColor: get-color('core-primary-500');
    margin-top: get-space('300');
    margin-bottom: get-space('400');

    &.rheostat-horizontal {
      width: calc(100% - var(--range-slider-handle-size));
    }

    &-background {
      background-color: #{get-color('neutrals-tertiary-500')};
      border-color: #{get-color('neutrals-tertiary-500')};
      border: none;
      border-radius: 9999px;
      margin-inline-start: -2.5%;
      width: 105%;
    }

    &-progress {
      border: none;
    }

    &-tooltip, &-value {
      display: none;
    }

    &-handle {
      width: var(--range-slider-handle-size);
      height: var(--range-slider-handle-size);
    }

    &-handle, &-progress {
      background-color: $rangeSliderBorderColor;
      border-color: #{get-color('grayscale-tertiary-400')};
    }
  }
}