.b-graphCard {
  --gutter: .5em;
  
  &__graph {
    max-width: 5rem;
  }

  canvas {
    width: 100%;
  }

  @include media-query('md') {
    max-width: 18rem;
  }
}