.b-agentProfileHero {
  margin-block-start: 0;

  .b-agentHeroImage {
    --n: 4;
    --d: 1;
  }

  &__content {
    margin-top: -4rem;
  }
}

.b-agentProfile {
  .b-agentDetailHeader {
    .c-frame {
      max-width: 8rem;
    }
  }

  // This can eventually be replaced with new b-richText
  .b-bio {
    --flow-space: #{get-space('500')};
    --sidebar-width: 100%;
    --sidebar-content-min-width: 8rem;
    --sidebar-gutter: 0.5rem;

    h6 {
      @include eyebrow;
      text-transform: uppercase;
      font-weight: 700;
      font-size: get-text-size('1');
      margin-block-start: get-space('600');
    }

    figure {
      @include frame;

      iframe {
        width: 100% !important;
        height: 100% !important;
      }
    }

    @include media-query('xs') {
      --flow-space: #{get-space('400')};
      --sidebar-width: 14rem;
      --sidebar-gutter: 2rem;
    }

    @include media-query('sm') {
      --sidebar-gutter: 3rem;
    }
  }

  .b-agentProfileCard {
    --icon-spacing: 0.5em;
    --eyebrow-color: #{get-color('core-primary-500')};
    --box-dark: #{get-color('neutrals-tertiary-400')};

    .c-flow {
      align-items: center;
    }

    .subheadline {
      font-size: get-text-size('3');
    }

    .eyebrow {
      color: get-color('core-primary-500');
      font-size: 0.875em;
    }

    ul {
      list-style: none;
    }

    &__list {
      strong {
        color: #60451f;
      }
    }
  }

  .b-socialLinks {
    --gutter: #{get-space('500')};
    --icon-size: 1.5625rem;
    color: var(--theme-primary);
  }

  .b-credentials {
    --auto-grid-min-item-size: 8rem;
  }
}
