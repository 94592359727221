.b-proseCard {
  width: 100%;
  display: flex;
  margin-top: get-space('500');

  &__content {
    &__headline {
      font-size: get-text-size('3');
      line-height: 34px;
    }

    &--vertical {
      margin-top: get-space('500');
    }

    &--horizontal {
      margin-left: get-space('500');
    }

    &__description {
      font-size: get-text-size('1');
      letter-spacing: 0.01em;
      line-height: 32px;

      &--truncate {
        display: -webkit-box;
        -webkit-line-clamp: var(--line-clamp, 3);
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
      }
    }
  }

  &--featuredHalf {
    width: 100%;

    @include media-query('lg') {
      width: 50%;
    }
  }

  &--featuredThird {
    width: 100%;

    @include media-query('lg') {
      width: 33.33%;
    }
  }
}