/* templates/_/macros/components macro documentsTable
 * A grid view of downloadable documents
 * Used on individual property listing pages
 */
.b-relatedDocuments {
  --box-dark: #{get-color('grayscale-primary-300')};
  --box-padding: #{get-space('700')};
  --eyebrow-color: #{get-color('grayscale-primary-500')};
  --gutter: #{get-space('600')};
  --icon-size: 1.125em;

  &__list {
    .b-document {
      
    }
  }

  .b-document {
    --box-dark: #{get-color('grayscale-primary-100')};
    --box-padding: #{get-space('400')};

    &:not(:last-of-type) {
      border-bottom: 1px solid get-color('neutrals-primary-100');
    }

    .b-icon {
      fill: get-color('neutrals-primary-100');
    }

    // Hover State
    &__title, .b-icon {
      transform: translate(0);
      transition: transform .5s ease-in-out;
    }

    &:focus,
    &:hover {
      .b-document {
        &__title {
          transform: translateX(1.5rem);
        }
      }

      .b-icon {
        transform: translateX(-1.5rem);
      }
    }
  }
}
