/** FLOW
 * @see EL access: https://api.every-layout.dev/view?email=matt@inlikealion.com
 * @see https://every-layout.dev/layouts/stack/
 * A simple layout that adds spacing between elements via their parent
 * Values for margins can be found within _tokens.scss under $gorko-space-scale

 * CUSTOM PROPERTIES AND CONFIGURATION
 * --flow-space (): Determines the amount of space
 * between items
 */

.c-flow {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .errors,
  .error {
    color: red;
    list-style: none;
  }

  & > * + * {
    margin-block-start: var(--flow-space, get-space('400'));
  }

  &--page {
    & > * + * {
      margin-block-start: var(--page-flow-space, get-space('900'));
    }
  }

  /** Split Stack Code
    * .c-flow:only-child {
    *    block-size: 100%;
    * }

    * Where n is the child nodes number where the stack
    * should split
    * .c-flow > :nth-child(n) {
    *    margin-block-end: auto;
    * }
    */

  &--horizontal {
    flex-direction: row;
    margin-left: calc(var(--flow-space, get-space('400')) * -1);
    margin-right: calc(var(--flow-space, get-space('400')) * -1);

    // Needs to happen on all the children, not just siblings
    & > * {
      margin-left: var(--flow-space, get-space('400'));
      margin-right: var(--flow-space, get-space('400'));
    }
  }
}

.u-flow-space {
  @include spacingUtility('--flow-space');
}

.u-page-flow-space {
  @include spacingUtility('--page-flow-space');
}

@include media-query('md') {
  .md\:u-flow-space {
    @include spacingUtility('--flow-space');
  }
}

@include media-query('md') {
  .md\:u-page-flow-space {
    @include spacingUtility('--page-flow-space');
  }
}
