.b-profileMain {
  --icon-spacing: 0.5em;
  min-height: 61vh;

  &__eyebrow {
    color: var(--theme-primary);
    font-size: 0.75em;
    letter-spacing: 0.0625em;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 800;
    line-height: 1.125;

    &--profileImage {
      font-size: get-text-size('00');
    }

    &--profile {
      font-size: get-text-size('0');
    }
  }

  &__title {
    font-weight: 800;
    font-size: get-text-size('4');
    line-height: 1.257;
    letter-spacing: -1px;
    color: get-color('neutrals-primary-500');
  }

  &__section {
    font-weight: 700;
    font-size: get-text-size('3');
    line-height: 1.142;
    color: get-color('neutrals-primary-500');

    &__smText {
      font-size: 24px;
    }
  }

  &__profileInfo {
    font-weight: 700;
    font-size: get-text-size('3');
    line-height: 1.142;
    color: get-color('neutrals-primary-500');
  }

  &__dropdown {
    min-width: 200px;

    &--transparent {
      background-color: transparent;

      .b-input {
        background-color: transparent;
      }
    }

    &--truncate {
      max-width: 200px;
      padding-right: 40px;
      text-overflow: ellipsis;
    }
  }

  &__cardContainer {
    align-self: center;
    width: 100%;
  }

  &__formContainer {
    width: 100%;
  }

  &__userPhoto {
    background-color: get-color('neutrals-tertiary-400');
    max-height: 320px;
  }

  &__accordionContainer {
    .b-input {
      background-color: get-color('neutrals-tertiary-100');
    }
  }

  &__credentials {
    .b-button {
      padding: 0;
    }
  }

  &__credential {
    flex: 1 0 21%;
    font-size: get-text-size('0');

    label {
      cursor: pointer;
      padding: 15px 20px;
      width: 100%;
    }
  }

  &__credentialTitle {
    font-weight: 700;
    font-size: get-text-size('0');
    line-height: 1.285;
  }

  &__credentialTitle:hover {
    color: get-color('neutrals-tertiary-100');
    background-color: get-color('core-primary-500');
  }
  &__buttonContainer {
    cursor: pointer;
  }

  &__searchTitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.33;
    letter-spacing: -1px;
    color: get-color('neutrals-primary-500');
  }

  &__searchDate {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: 0.01em;
    color: get-color('neutrals-primary-300');
  }

  &__searchLink {
    font-weight: 800;
    font-size: 12px;
    line-height: 1;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .b-propertyStatus {
    font-size: get-text-size('0');
  }

  &:focus-visible,
  &:focus,
  &:hover {
    // Add slight hover here, html should be laid out closer to listings
  }

  &__form {
    column-gap: 30px;
  }

  &__button {
    font-weight: 800;
    font-size: get-text-size('00');
    line-height: 1;
    letter-spacing: 1px;
  }

  &__info {
    font-weight: 400;
    font-size: get-text-size('00');
    line-height: 1.583;
    color: get-color('neutrals-primary-500');
  }
  &__inputBg {
    background-color: get-color('neutrals-tertiary-100');
    border-color: get-color('neutrals-tertiary-100');
  }

  &__row {
    &--half {
      width: 50%;
    }
  }

  &__flexGrow {
    flex-grow: 1;
  }
}
