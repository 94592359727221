/** Wrapper
 * @see web.dev https://web.dev/design-system/css-utilities/#wrapper
 * A horizontally centered wrapper that provides a consistent central column.
 * This should be used in all contexts where the content needs to be in the center of the page.

 * CUSTOM PROPERTIES AND CONFIGURATION
 * --wrapper-width-max (75rem): A fallback property that is used when clamp is
 * unavailable. When updating the --wrapper-width this is typically the
 * high end of the clamp.
 *
 * --wrapper-width (clamp(16rem, 95vw, 75rem)): A flexible width leveraging clamp
 *
 * --wrapper-padding (0): The inline (hor) padding of the element
 */

 @mixin wrapperWide() {
  $max-width: 85.75rem;
  --wrapper-width-max: #{$max-width};
  --wrapper-width: clamp(20rem, calc(100vw - 15px), #{$max-width});
 }

.c-wrapper {
  margin-inline: auto;
  width: var(--wrapper-width-max, 75rem);
  width: var(--wrapper-width, clamp(16rem, 95vw, 75rem));
  padding-inline: var(--wrapper-padding, 0);
  position: relative;

  &--extraNarrow {
    --wrapper-width-max: 31rem;
    --wrapper-width: clamp(14rem, 95vw, var(--wrapper-width-max));
  }

  &--narrow {
    --wrapper-width-max: 62rem;
    --wrapper-width: clamp(16rem, 95vw, var(--wrapper-width-max));
  }

  &--wide {
    @include wrapperWide();
  }

  // Sean: Adding this as an exception for now as opposed to overwriting
  // the core styles to avoid the possibility of unwanted global
  // changes
  // As an example the media and copy also utilizes the wrapper
  // however it uses box padding on the columns to solve the mobile
  // gutter issue. I do think these should be brought inline, but 
  // don't want to risk altering a component that is currently working
  &--responsive {
    --wrapper-width: 100%;
    padding-inline: get-space('400');

    @include media-query('md') {
      --wrapper-width: unset;

      &--wide {
        @include wrapperWide();
      }
    }
  }

  &--fullWidthMobile {
    --wrapper-width: 100%;

    @include media-query('md') {
      --wrapper-width: clamp(16rem, 95vw, 75rem);
    }
  }

  &--sideBreak {
    width: 100%;
    
    @include media-query('lg') {
      --wrapper-gap: calc(100% - clamp(16rem, 95vw, 75rem));
      --half-space: calc(var(--wrapper-gap) / 2);
      padding-left: var(--half-space);
    }
  }
}
