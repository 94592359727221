.b-faq {
  &__content {
    margin-bottom: get-space('500');

    .headline {
      letter-spacing: -1px;
    }
  }

  &__collapse {
    margin-left: auto;

    &__item {
      border-bottom: 1px solid rgba(get-color('neutrals-primary-100'), 0.2);
      list-style: none;
      margin-bottom: get-space('500');
      padding-bottom: get-space('500');

      &__button {
        position: relative;
        width: 100%;

        &:hover {
          .b-faq__collapse__item__icon {
            background: get-color('neutrals-tertiary-500');
          }
        }

        &--active {
          .b-faq__collapse__item__icon svg rect:first-child {
            transform: rotate(-90deg);
          }
        }
      }

      &__title {
        line-height: 1.625em;
        max-width: 460px;
      }

      &__icon {
        @include absolute($right: 0px, $top: 50%);
        color: var(--theme-primary);
        background: get-color('neutrals-tertiary-400');
        height: 50px;
        transform: translateY(-50%);
        transition: all $global-transition-base;
        width: 50px;

        svg rect {
          transform-origin: center;
          transition: all $global-transition-base;

          &:last-child {
            transform: rotate(-90deg);
          }
        }
      }

      &__body {
        // TODO: Missing color #7C6647
        color: get-color('neutrals-primary-500');
        display: none;
        overflow: hidden;
        transition: $global-transition-base;

        // workaround to add spacing at the top of the element, padding and margin cause an unexpected behavior in the transition
        &:before {
          @include pseudo;
          height: get-space('500');
        }

        &--open {
          display: block;
        }
      }
    }
  }

  @include media-query('md') {
    &__collapse,
    &__content {
      flex-grow: 0;
    }

    &__content {
      flex-basis: 34%;
    }
  }
}
