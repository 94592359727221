.ais-CurrentRefinements {
  $self: &;
  padding: get-space('200') 0;
  
  #{$self} {
    &-label {
      color: #{get-color('neutrals-primary-500')};
      opacity: .6;
    }

    &-category {
      --gutter: .25em;
  
      #{$self} {
        &-delete, &-categoryLabel {
          color: get-color('neutrals-primary-300');
        }
  
        &-delete {
          align-items: center;
          background: get-color('neutrals-tertiary-400');
          border-radius: 50%;
          display: flex;
          height: 1.5rem;
          justify-content: center;
          width: 1.5rem;
  
          &:hover {
            background-color: get-color('core-primary-500');
            color: get-color('grayscale-tertiary-400');
          }
        }
      }
    }
  }
}