.b-mediaAndCopy {
  --n: 3;
  --d: 2;
  --wrapper-width: 100%;

    /**
     * Media Column
     */
    .b-mediaAndCopy__media {
      --box-padding: 0;
    }

    .c-frame.c-frame--16-9 {
      --n: 16;
      --d: 9;
    }

    &__mediaInner {
      width: 100%;
    }

    .b-mediaAndCopy__copyContainer {
      p {
        max-width: 100%;
      }
    }

    /**
     * Copy Column
     */
    .b-mediaAndCopy__copy {
      --box-padding: unset;
      display: none;
      width: 100%;

      &--mobile {
        display: block;

      }

      > .c-flow {
        max-width: 60ch;
      }

      .copy {
        blockquote,
        em,
        strong {
          font: revert;
        }
        ol,
        ul {
          list-style-position: inside;
          margin-block: 1em;
          margin-inline-start: 0.625em;
        }
      }

      &__actions {
        width: 100%;

        & .actions-container {
          flex-shrink: 0;
        }

        div.accent {
          background-color: var(--color-grayscale-400);
          flex-grow: 1;
          height: 2px;
          margin-left: get-space("700");
          width: 100%;
        }
      }
    }

    /**
     * Desktop (above 'md') styles
     */
    .c-autoGrid {
      @include c-auto-grid-ordering-breakpoint('md');
    }

    @include media-query('md') {
      --box-padding: unset;
      --wrapper-width: unset;

      .c-autoGrid {
        --auto-grid-min-item-size: unset;
      }

      /**
       * Media Column
      */

      .c-frame:not(.c-frame--16-9) {
        aspect-ratio: unset;
        width: auto;
      }

      /**
       * Copy Column
       */
      .b-mediaAndCopy__copy {
        --flow-space: #{get-space('600')};
        display: block;

        &--mobile {
            display: none;
        }

        > .c-flow {
          width: auto;
        }
      }
    }
  }
