/* Search Dropdown
 * An instantsearch widget wrapper in a listing dropdown with options for search
 */

.b-searchDropdown {
  $self: &;
  flex-basis: 100%;

  .b-listingDropdown__content {
    button {
      width: 100%;
      text-align: left;
    }
  }

  .c-repel {
    width: 100%;
  }

  .b-loadingText {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    line-height: 1;
    font-size: 1rem;
  }

  .ais-SearchBox {
    button {
      display: flex;
      align-items: center;
    }

    &-form {
      display: flex;

      button {
        flex-shrink: 2;
      }

      .ais-SearchBox {
        &-submit {
          fill: var(--theme-primary);
          transform: scale(-1, 1);
        }

        &-reset {
          display: none;
          order: 2;
        }
      }

      input {
        flex-grow: 1;
        font-size: get-text-size('0');
        order: 1;
        padding: 0 0.25rem;
        margin: 0 0.25rem;
      }
    }
  }

  &--standAlone {
    & > button {
      --box-padding: #{get-space('400')} #{get-space('400')};
      border-bottom: 6px solid var(--theme-primary);
      border-radius: 5px;
    }

    .b-icon {
      --icon-size: 1rem;
      color: var(--theme-primary);
    }
  }

  @include media-query('sm') {
    flex-basis: unset;
  }
}

#listingMainSearchBar {
  --box-padding: #{get-space('200')} #{get-space('400')};
  --box-light: #{get-color('neutrals-primary-500')};

  border-radius: $global-radius-base;
  flex-grow: 1;

  .ais-SearchBox-submit {
    color: var(--theme-primary);
  }
}

.ais-ClearRefinements-button--disabled {
  color: get-color('grayscale-primary-100') !important;
}
