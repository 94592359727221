/* Instant Search Pagination Widget Styling
 * Used on listing pages
 */
.ais-Pagination-list:not(.b-listingPagination__main) {
  .ais-Pagination-item {
    border-radius: $global-radius-base;
    background-color: get-color('neutrals-tertiary-400');
    color: get-color('neutrals-primary-100');
    display: flex;
    height: 2.5rem;
    justify-content: center;
    min-width: 2.5rem;

    span,
    a {
      align-items: center;
      display: flex;
      height: 100%;
    }

    a.ais-Pagination-link {
      align-items: center;
      display: flex;
      min-width: 100%;
      justify-content: center;
      &:visited,
      &:link {
        color: inherit; // This may be better placed in global, unsure at the moment
      }
    }

    &--selected {
      background-color: var(--theme-primary);
      color: get-color('grayscale-tertiary-400');
    }

    &--previousPage,
    &--nextPage {
      a.ais-Pagination-link {
        background-color: var(--theme-primary);
        color: get-color('grayscale-tertiary-400');
        height: 3rem;
        min-width: 3rem;
      }
    }

    &--disabled {
      background-color: get-color('neutrals-tertiary-500');
      color: get-color('grayscale-tertiary-400');
    }

    // See note on ::before element
    // &--lastPage {
    //   order: 2;
    // }

    // &--previousPage {
    //   order: 4;
    // }

    // &--nextPage {
    //   order: 5;
    // }

    // &--firstPage, &--lastPage {
    //   display: none;
    // }

    &:not(.ais-Pagination-item--disabled):hover {
      background-color: var(--theme-primary);
      color: get-color('grayscale-tertiary-400');
      cursor: pointer;
      @include grungeBg;
    }
  }

  // Last page takes user to last page number that currently is displayed
  // Not to the last of all pages
  // &::before {
  //   color: get-color('grayscale-400');
  //   content: 'of';
  //   order: 1;
  // }

  &::after {
    background-color: get-color('core-primary-100');
    content: '';
    height: 1px;
    flex-grow: 1;
    order: 3;
  }
}
