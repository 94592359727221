/**
* Custom background
* For buttons and eyebrow
*/
@mixin custom-bg {
  background-blend-mode: screen, normal;
  background-image: url('/dist/img/bg-texture-grunge.jpg');
  background-size: 400px;
  background-position: center right;
}

@mixin bg-img() {
  background-image: url('/dist/img/bg-texture-grunge.jpg');
}

// Sean: I almost setup something similar but believe this isn't needed since
// SCSS's native RGB can handle #hex values
@mixin bg-color($color, $opacity) {
  background-color: rgba( red($color), green($color), blue($color), $opacity );
}
