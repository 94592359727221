/** STACK
 * @see EL access: https://api.every-layout.dev/view?email=matt@inlikealion.com
 * @see https://every-layout.dev/layouts/switcher/
 * A layout component for switching between a vertical and horizontal layout at a given container breakpoint

 * CUSTOM PROPERTIES AND CONFIGURATION

 * --switcher-gutter (): This defines the space between each item

 * --switcher-thresold (30rem): The threshold where items
 * will switch between layouts, from horizontal to
 * vertical.
 */

@mixin max-items($max-items) {
  & > :nth-last-child(n+ #{$max-items + 1}),
  & > :nth-last-child(n+ #{$max-items + 1}) ~ * {
    flex-basis: 100%;
  }
}

.c-switcher {
  display: flex;
  flex-wrap: wrap;
  gap: var(--switcher-gutter, get-space('400'));

  & > * {
    flex-grow: 1;
    flex-basis: calc(( var(--switcher-thresold, 30rem) - 100%) * 900);
  }

  /* #region - Max Items */
  @for $i from 2 through 5 {
    &--threshold-#{$i} {
      @include max-items($i);
    }
  }
  /* #endregion - Max Items */
}

.u-switcher-gutter {
  @include spacingUtility('--switcher-gutter');
}