.b-imageGrid {
  &__cell {
    margin-inline: auto;
  }

  .b-pill {
    position: absolute;
    bottom: #{get-space('200')};
    right: #{get-space('400')};
  }
}