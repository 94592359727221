.b-imageFader {
  li {
    display: block;
  }
  
  li:nth-of-type(2) {
    opacity: 0;
  }

  li:nth-of-type(n+3) {
    opacity: 0;
    img {
      pointer-events: none;
    }
  }
}

// Can be used statically or on hover
@mixin imageFader {
  li:not(.c-layer) {
    position: relative;
  }

  li:nth-of-type(2) {
    animation-delay: var(--fader-delay, 3s);
    animation-duration: var(--fader-duration, 1s);
    animation-name: var(--fader-animation, fade-in-shrink);
  }
}