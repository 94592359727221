// Listing Dropdown - Dropdowns that hold instantsearch form inputs
// Generally a custom dropdown
.b-listingDropdown {
  $self: &;
  --box-padding: #{get-space('200')} #{get-space('400')};
  --box-light: #{get-color('core-primary-100')};

  position: relative;

  .b-icon {
    color: get-color('neutrals-primary-500');
  }

  &__select {
    --box-padding: #{get-space('300')} #{get-space('400')};
    --box-dark: #{get-color('neutrals-tertiary-400')};
    --box-light: #{get-color('neutrals-primary-500')};
    border-radius: $global-radius-base;
    cursor: pointer;
    flex-grow: 1;
    font-size: get-text-size('0');
    position: relative;
    width: 100%;
  }

  &__caret {
    transition:
      transform 0.25s ease-in-out,
      color 0.25s ease-in-out;
  }

  &__content {
    --box-dark: #{get-color('neutrals-tertiary-300')};
    --box-light: #{get-color('neutrals-primary-500')};

    // border-top: 3px solid get-color('state-good');
    border-top: 3px solid var(--theme-primary);
    box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.2);
    display: none;
    position: absolute;
    max-width: 100vw;
    min-width: 100%;
    left: 0;
    top: 100%;

    .b-listingDropdown__content {
      box-shadow: none;
      background-color: get-color('grayscale-primary-300');
    }
  }

  &__controls {
    margin-block-start: get-space('400');

    & .c-cluster {
      justify-content: space-between;
      width: 100%;
    }
  }

  &__multiInput {
    margin-block-start: get-space('400');
  }

  &--large > .b-listingDropdown__content {
    border-radius: $global-radius-base 0 $global-radius-base $global-radius-base;
    width: 26rem;

    &.b-listingDropdown--alignRight {
      border-radius: 0 $global-radius-base $global-radius-base
        $global-radius-base;
    }
  }

  &--alignRight > .b-listingDropdown__content {
    left: auto;
    right: 0;
  }

  &--alignCenter > .b-listingDropdown__content {
    transform: translateX(-50%);
  }

  .b-btn {
    border-radius: $global-radius-base;
    font-weight: 600;
    transition: color 0.25s ease-in-out;

    &--primary {
      background-color: get-color('core-primary-100');
      color: get-color('grayscale-primary-300');

      &:hover {
        color: get-color('grayscale-primary-100');
      }
    }

    &--ghost {
      background-color: transparent;
      color: get-color('grayscale-primary-500');

      &:hover {
        color: get-color('core-primary-100');
      }
    }
  }

  &.open {
    z-index: 999;
    transform: translate3d(0, 0, 0);

    #{$self} {
      &__caret {
        color: var(--theme-primary);
        transform: rotate(-180deg);
      }
    }

    & > .b-listingDropdown {
      &__select {
        border-radius: $global-radius-base $global-radius-base 0 0;

        & > .b-listingDropdown__caret {
          color: get-color('core-primary-500');
          transform: rotate(-180deg);
        }
      }

      &__content {
        display: block;
      }
    }

    &.b-listingDropdown--shadow {
      & > .b-listingDropdown__select {
        box-shadow: 0px -3px 6px 0px rgb(0 0 0 / 15%);
        z-index: 110;

        &::before {
          box-shadow: -2px 0px 6px 0px rgb(0 0 0 / 15%);
          content: '';
          height: 100%;
          position: absolute;
          right: 100%;
          width: 1px;
        }

        &::after {
          box-shadow: 2px 0px 6px 0px rgb(0 0 0 / 15%);
          content: '';
          height: 100%;
          position: absolute;
          left: 100%;
          width: 1px;
        }
      }
    }
  }

  button {
    font-size: 0.725em;
  }

  .ais-RefinementList {
    &-label {
      font-size: 0.725em;
    }
    &-count {
      display: none;
    }
  }

  .ais-RangeInput {
    &-label {
      flex-grow: 1;
    }

    &-input {
      width: 100%;
    }

    &-submit {
      display: none;
    }
  }
}
