.b-propertyCardGrid {
  --auto-grid-min-item-size: 18rem;

  &__copy {
    width: 100%;

    &__copyBlock {
      &-headline {
        flex-grow: 1;
      }
      &-actions {
        align-self: flex-end;
      }
    }
  }
}

// Pulled out so it can be easily seperated into it's own component
.b-propertyCardGrid__categories {
  &__item {
    background-color: get-color('neutrals-tertiary-400');
    font-size: get-text-size('1');
    line-height: 2.44444;
    transition: background-color .3s ease-in-out;

    a {
      padding: 0 var(--box-padding, get-space('400'));
      transition: color .3s ease-in-out;
      z-index: 1;
    }

    span {
      align-self: center;
      white-space: nowrap;
    }

    &:focus,
    &:hover {
      background-color: get-color('core-primary-500');
      a {
        color: get-color('grayscale-tertiary-400');
      }
    }
  }
}
