/** Breakout
 * @see EL access: https://api.every-layout.dev/view?email=matt@inlikealion.com
 * @see https://web.dev/design-system/css-compositions/#breakout
 * A layout that allows you to break an element out of the
 * bounds of it's parent, with a sensible lock to prevent it
 * bleeding out

 * CUSTOM PROPERTIES AND CONFIGURATION
 * --breakout-max-width: This defines the maximum width of
 * the element.
 */

.c-breakout {
  width: calc(100vw - #{$global-gutter-narrow * 2});
  max-width: var(--breakout-max-width, 65rem);
  margin-left: 50%;
  transform: translateX(-50%);
}
